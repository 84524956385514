import request from "@/api/request.js";
// 这里面存关于设置的请求
// 机构详情
export function xqList(data) {
  return request({
    url: "api/organization/organization/getInfo",
    method: "get",
    params: data,
  });
}
// 管理员
export function adminList(data) {
  return request({
    url: "api/organization/info/list",
    method: "post",
    data: data,
  });
}
//   机构关键字详情
export function organizaKeyword(data) {
  return request({
    url: "api/organization/organizaKeyword/getInfo",
    method: "get",
    params: data,
  });
}
export function organizaKeywordNew(data) {
  return request({
    url: "api/system/keyWord/getInfo",
    method: "get",
    params: data,
  });
}
//   区域关键字下级列表
export function subordinatekeys(data) {
  return request({
    url: "api/system/regional/list",
    method: "post",
    data: data,
  });
}
//   机构关键字修改
export function KeywordEdit(data) {
  return request({
    url: "api/organization/organizaKeyword/edit",
    method: "post",
    data: data,
  });
}
// 机构关键字添加
export function KeywordAdd(data) {
  return request({
    url: "api/organization/organizaKeyword/add",
    method: "post",
    data: data,
  });
}
// 区域关键字
export function KeywordRegion(data) {
  return request({
    url: "api/system/regional/getInfo",
    method: "get",
    params: data,
  });
}
export function getTreeData(data) {
  return request({
    url: "api/organization/warnmodel/getWarnInfo",
    method: "get",
    params: data,
  });
}
// 提交表单
export function postForm(data) {
  return request({
    url: "api/organization/warnmodel/add",
    method: "post",
    data: data,
  });
}
//机构类型管理详情
export function findById(data) {
  return request({
    url: "api/system/type/findById",
    method: "get",
    params: data,
  });
}
//机构类型管理保存
export function keyWordAdd(data) {
  return request({
    url: "api/organization/organizaKeyword/edit",
    method: "post",
    data: data,
  });
}
//机构类型管理保存
export function keyWordAddNew(data) {
  return request({
    url: "api/system/keyWord/edit",
    method: "put",
    data: data,
  });
}
// 预警模型设置
export function Model(data) {
  return request({
    url: "api/organization/warnmodel/list",
    method: "post",
    data: data,
  });
}
// 预警事件
//编辑的懒加载树状图
export function modelEvents(data) {
  return request({
    url: "api/system/event/list",
    method: "post",
    data: data,
  });
}

// 标签列表
export function labelList(data) {
  return request({
    url: "api/msgquery/label/list",
    method: "get",
    params: data,
  });
}
// 标签新增
export function addLabel(data) {
  return request({
    url: "api/msgquery/label",
    method: "post",
    data: data,
  });
}
// 删除标签
export function deleteLabel(data) {
  return request({
    url: "api/msgquery/label/remove",
    method: "post",
    data: data,
  });
}
// 编辑标签
export function editLabel(data) {
  return request({
    url: "api/msgquery/label",
    method: "put",
    data: data,
  });
}
// 操作日志列表
export function journalList(data) {
  return request({
    url: "api/system/operlog/selectClientLists",
    method: "get",
    params: data,
  });
}
// 导出操作日志
export function importOperation(data) {
  return request({
    url: "api/system/operlog/exportClient",
    method: "post",
    data: data,
    responseType: "blob",
  });
}
// 登陆日志列表
export function loginList(data) {
  return request({
    // url: "dev-api/system/logininfor/clentList",
    url: "api/system/logininfor/clentList",
    method: "get",
    params: data,
  });
}
// 导出登陆日志
export function importLogin(data) {
  return request({
    url: "api/system/logininfor/exportClient",
    method: "post",
    data: data,
    responseType: "blob",
  });
}
// 关于我的上报境外数据
export function isReportList(data,url) {
  return request({
    url: "api/msgquery/data/reportList"+url,
    method: "post",
    data: data,
  });
}
// 上报的精准舆情列表

export function reportList(data, url) {
  return request({
    url: "api/msgquery/sentiment/reportList" + url,
    method: "post",
    data: data,
  });
}
// 机构关键字配置

export function getInfo(data) {
  return request({
    url: "/api/system/regional/getAreaKeyWordList",
    method: "get",
    params: data,
  });
}
// export function reportList(data) {
//   return request({
//     url: "api/msgquery/sentiment/reportList",
//     method: "post",
//     data: data,
//   });
// }

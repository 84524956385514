<template>
  <!-- eslint-disable -->
  <div class="organization_detail center-inner settings">
    <!-- 头部 -->
    <p class="index-title">系统设置</p>
    <!-- 主体 -->
    <div class="organization_detail_content">
      <!-- 导航 -->
      <div :class="[
        'organization_detail_nav',
        navBarFixed == true ? 'navBarWrap' : '',
      ]">
        <el-tabs v-model="activeName" @tab-click="goAssignBlock(activeName)">
          <el-tab-pane label="基本信息" name="first"></el-tab-pane>
          <el-tab-pane label="登录日志" name="second"></el-tab-pane>
          <el-tab-pane label="操作日志" name="third"></el-tab-pane>
          <el-tab-pane v-if="$store.state.user.scope[0] || $store.state.user.scope[1]" label="我的标签"
            name="fourth"></el-tab-pane>
          <el-tab-pane v-if="$store.state.user.scope[0] || $store.state.user.scope[1]" label="我的收藏"
            name="five"></el-tab-pane>
          <el-tab-pane v-if="$store.state.user.isreport &&
            ($store.state.user.scope[0] || $store.state.user.scope[1])
            " label="我的上报" name="six"></el-tab-pane>
          <el-tab-pane label="配置模板" name="seven"></el-tab-pane>
        </el-tabs>
      </div>
      <!-- 组件位置 -->
      <component ref="compent" :is="activeName"></component>
      <!-- <first /> -->
    </div>
  </div>
</template>

<script>
import first from "../../views/systemSettings/component/first";
import second from "../../views/systemSettings/component/second";
import third from "../../views/systemSettings/component/third";
import fourth from "../../views/systemSettings/component/fourth";
import five from "../../views/systemSettings/component/five";
import six from "../../views/systemSettings/component/six";
import seven from "../../views/systemSettings/component/seven";
export default {
  data() {
    return {
      activeName: sessionStorage.getItem("activeName")
        ? sessionStorage.getItem("activeName")
        : "first",
      navBarFixed: false,
    };
  },
  components: {
    first,
    second,
    third,
    fourth,
    five,
    six,
    seven
  },
  methods: {
    // 点击导航栏切换的时候
    goAssignBlock(el) {
      sessionStorage.setItem("activeName", el);
      sessionStorage.setItem("active", "");
      sessionStorage.setItem("activeReport", "");
      this.$store.state.activeName = el;
      if (el == "fourth") {
        this.$refs.compent.name = "biaoqian";
      }
    },
    //导航栏固定
    watchScroll() {
      var scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      //  当滚动超过 90 时，实现吸顶效果
      if (scrollTop > 90) {
        this.navBarFixed = true;
      } else {
        this.navBarFixed = false;
      }
    },
  },
  created() {
    sessionStorage.setItem("setting", "yes");
  },
  mounted() {
  },
};
</script>

<style scoped>
.settings .top_img,
.settings .top_font {
  display: inline-block;
  vertical-align: middle;
}

.settings .top_img {
  cursor: pointer;
}

.settings .top_font {
  font-size: 20px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: bold;
  color: #333333;
  margin: 0 24px 0 16px;
}

.settings .organization_detail_content {
  padding: 0 24px 24px 24px;
}

.settings .organization_detail_nav {
  padding-left: 34px;
  background: #fff;
  margin-top: 24px;
  border-radius: 4px;
}

.settings .index-title {
  margin-top: 24px;
  margin-left: 24px;
}

.navBarWrap {
  position: fixed;
  top: 56px;
  z-index: 10;
  width: 85%;
}
</style>


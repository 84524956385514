<template>
  <div>
    <component :labelId="labelId" :type="type" :is="name" @changeHandle="changeHandle"></component>
  </div>
</template>

<script>
import biaoqian from "../../systemSettings/component/bioaqian";
import biaoRouter from "../component/biaoRouter"
export default {
  data() {
    return {
      name: "biaoqian",
      // 标签id
      labelId:0,
      type:0
    };
  },
  components: {
    biaoqian,
    biaoRouter
  },
  created() {},
  methods: {
    // 子组件传递过来的id值
    changeHandle(row,type) {
      this.type = type
      this.labelId = row.labelId
      // this.id = id
      this.name = 'biaoRouter'
    }
  }
};
</script>

<style scoped>
</style>

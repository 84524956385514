<template>
  <!-- eslint-disable -->
  <div class="organizationManagement center-inner setting-third">
    <!-- 头部 -->
    <div class="organizationManagement_top">
      <div class="organizationManagement_top_content">
        <div class="el_search">
          <div class="el_search_input">
            <el-select v-model="search.type" style="width:120px">
              <el-option label="按操作人" value="1"></el-option>
              <el-option label="按操作内容" value="2"></el-option>
            </el-select>
            <el-input
              clearable
              style="width: 200px;margin-left: 15px;"
              placeholder="请输入搜索内容"
              v-model="search.value"
            >
              <i slot="prefix" class="el-input__icon el-icon-search"></i>
            </el-input>
            <span class="el_font">查询时间</span>
            <!-- <el-date-picker
              value-format="yyyy-MM-dd"
              size="medium"
              class="picker-data"
              v-model="search.timer"
              type="daterange"
              range-separator="—"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            ></el-date-picker> -->
            <el-date-picker
              v-model="search.timer[0]"
              type="date"
              size="medium"
              value-format="yyyy-MM-dd"
              placeholder="开始日期">
            </el-date-picker>
            -
            <el-date-picker
              v-model="search.timer[1]"
              type="date"
              size="medium"
              value-format="yyyy-MM-dd"
              placeholder="结束日期">
            </el-date-picker>
            <span class="el_font">状态</span>
            <el-select v-model="search.id" style="width: 190px" placeholder="全部状态" clearable>
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item"
              ></el-option>
            </el-select>
          </div>
          <div class="el_search_button">
            <el-button type="primary" size="medium" @click="searchList">查询</el-button>
            <el-button size="medium" @click="Remake">重置</el-button>
          </div>
        </div>
      </div>
    </div>
    <!-- 主体 -->
    <div class="organizationManagement_content">
      <div class="organizationManagement_content_top">
        <!-- <div style="overflow: hidden;">
          <el-button
          :disabled="loadingList"
            type="success"
            plain
            size="medium"
            class="xinzeng_z"
            @click="importOperation"
          >导出日志</el-button>
        </div>-->
        <div class="tags" v-if="tags != ''">
          <span class="tags-title">条件：</span>
          <el-tag
            v-for="tag in tags"
            closable
            @close="handleClose(tag, tags)"
            :key="tag.name"
            :type="tag.type"
          >
            <span>{{ tag.text }}:</span>
            <span>{{ tag.value }}</span>
          </el-tag>
        </div>
        <!-- 编辑 -->
      </div>
      <div class="content_table">
        <div>
          <el-table
            v-loading="loading"
            :data="tableData"
            stripe
            :header-cell-style="headerStyle"
            :cell-style="cellStyle"
            style="width: 100%; text-align: center; border: 1px solid #e4e6ec"
          >
            <el-table-column label="序号" :index="indexMethod" type="index" width="200"></el-table-column>
            <el-table-column label="操作类型" width="180">
              <template slot-scope="scope">
                <!-- 0=其它,1=新增,2=修改,3=删除,4=授权,5=导出,6=导入,7=强退,8=生成代码,9=清空数据,10=查询 -->
                <div>
                  {{
                  scope.row.businessType == 0
                  ? "其他"
                  : scope.row.businessType == 1
                  ? "新增"
                  : scope.row.businessType == 2
                  ? "修改"
                  : scope.row.businessType == 3
                  ? "删除"
                  : scope.row.businessType == 4
                  ? "授权"
                  : scope.row.businessType == 5
                  ? "导出"
                  : scope.row.businessType == 6
                  ? "导入"
                  : scope.row.businessType == 7
                  ? "强退"
                  : scope.row.businessType == 8
                  ? "生成代码"
                  : scope.row.businessType == 9
                  ? "清空数据"
                  : scope.row.businessType == 10?"查询":""
                  }}
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="operName" label="操作人" width="250"></el-table-column>
            <el-table-column prop="title" label="操作详情"></el-table-column>
            <el-table-column label="结果" width="180">
              <template slot-scope="scope">
                <div
                  :class="[scope.row.status == 0 ? 'success' : 'fail']"
                >{{ scope.row.status == 0 ? "成功" : "失败" }}</div>
              </template>
            </el-table-column>
            <el-table-column prop="operTime" label="时间"></el-table-column>
          </el-table>
          <div class="block">
            <span class="total">
              共{{ tablePage.total }}条, 当前显示第{{
              tablePage.page * tablePage.pageSize - tablePage.pageSize + 1
              }}-{{
              tablePage.page * tablePage.pageSize > tablePage.total
              ? tablePage.total
              : tablePage.page * tablePage.pageSize
              }}条
            </span>
            <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="tablePage.page"
              :page-sizes="tablePage.pageSizes"
              :page-size="tablePage.pageSize"
              layout="sizes, prev, pager, next,jumper"
              :total="tablePage.total"
            ></el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { journalList, importOperation } from "../../../api/setting/list";
export default {
  data() {
    return {
      loading: false,
      loadingList: false,
      headerStyle: {
        background: "#F6F7FA",
        textAlign: "center",
        fontSize: "16px",
        color: "#333"
      },
      cellStyle: {
        textAlign: "center"
      },

      //页数
      tablePage: {
        pageSizes: [10, 20, 30, 40, 50],
        page: 1,
        pageSize: 10,
        total: 0
      },
      currentPage: 1,
      options: [
        {
          value: 0,
          label: "成功"
        },
        {
          value: 1,
          label: "失败"
        }
      ],
      tags: [],
      // 开始时间
      beginTime: "",
      // 结束时间
      endTime: "",
      search: {
        id: "",
        type: "1",
        timer: [],
        value: ""
      },
      allPeople: 0,
      tableData: []
    };
  },
  created() {
    this.journalList();
  },
  methods: {
    searchList() {
      if(this.search.timer[0]&&this.search.timer[1]&&this.search.timer[0]>this.search.timer[1]){
        this.$message.warning("请选择正确的时间段");
        return;
      }
      if(!this.search.timer[0]||!this.search.timer[1]){
        this.search.timer = [];
      }
      this.tags = [];
      this.tablePage.page = 1;
      if(this.search.value){
        if(this.search.type == "1"){
          this.tags.push({
            text: "操作人",
            value: this.search.value
          });
        }
        if(this.search.type == "2"){
          this.tags.push({
            text: "操作详情",
            value: this.search.value
          });
        }
      }
      if (this.search.timer&&this.search.timer[0]&&this.search.timer[1]) {
        this.beginTime = this.search.timer[0];
        this.endTime = this.search.timer[1];
        this.tags.push({
          text: "开始时间",
          value: this.beginTime
        });
        this.tags.push({
          text: "结束时间",
          value: this.endTime
        });
        this.beginTime = this.beginTime+" 00:00:00";
        this.endTime = this.endTime+" 23:59:59";
      }
      if (this.search.id != "") {
        this.tags.push({
          text: "状态",
          value: this.search.id.label
        });
      }
      this.journalList();
    },
    // 重置
    Remake() {
      this.tags = [];
      this.search = {
        id: "",
        tyoe: "1",
        timer: [],
        value: ""
      };
      (this.beginTime = ""), (this.endTime = ""), this.journalList();
    },
    handleClose(tag, tags) {
      tags.splice(tags.indexOf(tag), 1);
      if (tag.text == "操作人"||tag.text == "操作详情") {
        this.search.value = "";
      } else if (tag.text == "状态") {
        this.search.id = "";
      } else {
        this.search.timer = [];
        this.beginTime = "";
        this.endTime = "";
      }
      this.searchList();
    },
    // 刷新页面
    async journalList() {
      this.loading = true;
      let data = {
        pageSize: this.tablePage.pageSize,
        pageNum: this.tablePage.page,
        beginTime: this.beginTime,
        endTime: this.endTime,
        status: this.search.id.value
      };
      if(this.search.value){
        if(this.search.type == "1"){
          data.operName = this.search.value;
        }
        if(this.search.type == "2"){
          data.title = this.search.value;
        }
      }

      const res = await journalList(data);
      if (res != undefined) {
        this.tableData = res.data.rows;
        this.tablePage.total = res.data.total;
      }
      this.loading = false;
    },
    async importOperation() {
      this.loadingList = true;
      const res = await importOperation({});
      let blob = new Blob([res.data], {
        type:
          "application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      });
      if (window.navigator.msSaveOrOpenBlob) {
        navigator.msSaveBlob(blob);
      } else {
        let elink = document.createElement("a");
        elink.download = "操作日志.xlsx";
        elink.style.display = "none";
        elink.href = URL.createObjectURL(blob);
        document.body.appendChild(elink);
        elink.click();
        document.body.removeChild(elink);
      }
      this.loadingList = false;
    },
    // 分页
    handleSizeChange(val) {
      this.tablePage.pageSize = val;
      this.tablePage.page = 1;
      this.journalList();
      window.scrollTo(0, 0);
    },
    handleCurrentChange(val) {
      this.tablePage.page = val;
      this.journalList();
      window.scrollTo(0, 0);
    },
    // 序号
    indexMethod(index) {
      return (
        index +
        this.tablePage.page * this.tablePage.pageSize -
        this.tablePage.pageSize +
        1
      );
    }
  }
};
</script>

<style scoped>
.setting-third .organizationManagement_title {
  color: #1a1a1a;
  font-size: 26px;
  font-weight: bold;
  margin-bottom: 24px;
}

.setting-third .organizationManagement_top {
  background: #ffffff;
  border-radius: 4px;
}

.setting-third .organizationManagement_top_content {
  margin-top: 16px;
  padding: 22px 24px;
}

.setting-third .el_font {
  margin-left: 32px;
  margin-right: 12px;
  color: #333333;
  font-weight: bold;
  font-size: 14px;
}

.setting-third .organizationManagement_content {
  background: #ffffff;
  border-radius: 4px;
  padding: 24px;
  margin-top: 16px;
}

.setting-third .organizationManagement_content_top {
  margin-bottom: 16px;
  overflow: hidden;
}
.setting-third .allPeople {
  float: left;
  font-size: 16px;
  color: #333;
}

.setting-third .export_z,
.setting-third .xinzeng_z,
.setting-third .exportIn_z {
  float: right;
}

.setting-third .bianji,
.setting-third .table_line,
.setting-third .shanchu {
  display: inline-block;
  vertical-align: middle;
}

.setting-third .table_line {
  width: 1px;
  height: 14px;
  background: #e4e6ec;
  margin: 0 16px;
}

.setting-third .bianji {
  color: #2e59ec;
  padding: 0;
}

.setting-third .success {
  color: #02bc7c;
}

.setting-third .el_search {
  display: flex;
  justify-content: space-between;
}

.setting-third .el_search_input {
  float: left;
}

.setting-third .el_search_button .el-button {
  width: 80px;
  height: 36px;
}
.setting-third .el-tag {
  height: 22px;
  line-height: 22px;
}
.setting-third .name-wrapper {
  cursor: pointer;
  color: #2e59ec;
}
.setting-third .span-img {
  position: absolute;
  top: 0;
  left: 0;
}
.setting-third .span-img img {
  width: 18px;
  height: 18px;
}
</style>

<template>
  <div class="seven">
    <div class="seven-top">
      <img src="@/assets/img/views/juzhong.png" />
      <span class="span1">自定义简报</span>
      <div class="seven-illustrate" @click="lookIllustrate">参数说明</div>
    </div>
    <p class="p1">可以对舆情信息复制的内容进行个性化设置</p>
    <div class="radio">
      <el-radio v-model="radio" :label="0">启用默认</el-radio>
      <el-radio v-model="radio" :label="1">自定义修改</el-radio>
    </div>
    <div class="custom" v-if="radio == 1">
      <div class="custom-left">
        <div class="custom-title">
          <span>模版设置</span>
        </div>
        <el-input class="custom-cont" resize="none" type="textarea" :rows="22" v-model="textarea">
        </el-input>
      </div>
      <div class="custom-right">
        <div class="custom-title">
          <span>预览效果</span>
          <div @click="previewCopy">预览</div>
        </div>
        <el-input class="custom-cont" :disabled="true" resize="none" type="textarea" :rows="22" v-model="textarea2">
        </el-input>
      </div>
    </div>
    <div class="custom-save" @click="customSave">保存</div>
    <!-- 参数说明 -->
    <el-dialog title="参数说明" :visible.sync="dialogVisible" :close-on-click-modal="false" :close-on-press-escape="false"
      width="700px">
      <div class="dialogcenter">
        <div class="table">
          <div class="table-row grey-row title-row">
            <div class="table-col table-col1">字段</div>
            <div class="table-col table-col2">参数</div>
            <div class="table-col table-col1">字段</div>
            <div class="table-col table-col2">参数</div>
          </div>
          <div class="table-row">
            <div class="table-col table-col1">标题</div>
            <div class="table-col table-col2">
              <span>%&标题%&</span>
              <img src="../../../assets/img/views/copy.png" class="copy" @click="copy('%&标题%&')" />
            </div>
            <div class="table-col table-col1">调性</div>
            <div class="table-col table-col2">
              <span>%&调性%&</span>
              <img src="../../../assets/img/views/copy.png" class="copy" @click="copy('%&调性%&')" />
            </div>
          </div>
          <div class="table-row grey-row">
            <div class="table-col table-col1">链接</div>
            <div class="table-col table-col2">
              <span>%&链接%&</span>
              <img src="../../../assets/img/views/copy.png" class="copy" @click="copy('%&链接%&')" />
            </div>
            <div class="table-col table-col1">敏感度</div>
            <div class="table-col table-col2">
              <span>%&敏感度%&</span>
              <img src="../../../assets/img/views/copy.png" class="copy" @click="copy('%&敏感度%&')" />
            </div>
          </div>
          <div class="table-row">
            <div class="table-col table-col1">摘要</div>
            <div class="table-col table-col2">
              <span>%&摘要%&</span>
              <img src="../../../assets/img/views/copy.png" class="copy" @click="copy('%&摘要%&')" />
            </div>
            <div class="table-col table-col1">热搜情况</div>
            <div class="table-col table-col2">
              <span>%&热搜情况%&</span>
              <img src="../../../assets/img/views/copy.png" class="copy" @click="copy('%&热搜情况%&')" />
            </div>
          </div>
          <div class="table-row grey-row">
            <div class="table-col table-col1">发布时间</div>
            <div class="table-col table-col2">
              <span>%&发布时间%&</span>
              <img src="../../../assets/img/views/copy.png" class="copy" @click="copy('%&发布时间%&')" />
            </div>
            <div class="table-col table-col1">AI简述</div>
            <div class="table-col table-col2">
              <span>%&AI简述%&</span>
              <img src="../../../assets/img/views/copy.png" class="copy" @click="copy('%&AI简述%&')" />
            </div>
          </div>
          <div class="table-row">
            <div class="table-col table-col1">来源</div>
            <div class="table-col table-col2">
              <span>%&来源%&</span>
              <img src="../../../assets/img/views/copy.png" class="copy" @click="copy('%&来源%&')" />
            </div>
            <div class="table-col table-col1">省</div>
            <div class="table-col table-col2">
              <span>%&省%&</span>
              <img src="../../../assets/img/views/copy.png" class="copy" @click="copy('%&省%&')" />
            </div>
          </div>
          <div class="table-row grey-row">
            <div class="table-col table-col1">媒体</div>
            <div class="table-col table-col2">
              <span>%&媒体%&</span>
              <img src="../../../assets/img/views/copy.png" class="copy" @click="copy('%&媒体%&')" />
            </div>
            <div class="table-col table-col1">市</div>
            <div class="table-col table-col2">
              <span>%&市%&</span>
              <img src="../../../assets/img/views/copy.png" class="copy" @click="copy('%&市%&')" />
            </div>
          </div>
          <div class="table-row">
            <div class="table-col table-col1">作者</div>
            <div class="table-col table-col2">
              <span>%&作者%&</span>
              <img src="../../../assets/img/views/copy.png" class="copy" @click="copy('%&作者%&')" />
            </div>
            <div class="table-col table-col1">区</div>
            <div class="table-col table-col2">
              <span>%&区%&</span>
              <img src="../../../assets/img/views/copy.png" class="copy" @click="copy('%&区%&')" />
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { setCustomCopy, getCustomCopy } from "../../../api/message/list";
export default {
  data() {
    return {
      radio: 0,
      dialogVisible: false,
      textarea: "",
      textarea2: "",
      copyText: {
        "%&标题%&": "鹏瑞半山云璟官方售楼处电话|鹏瑞半山云璟地址|深圳鹏瑞半山云璟售楼中心发布",
        "%&调性%&": "中性",
        "%&链接%&": "https://www.zhifang.com/news1/2657664.html",
        "%&敏感度%&": "非敏感",
        "%&摘要%&": "2023-08-19 14:24:10发布者：赛居营销 鹏瑞半山云璟\n售楼处电话：400-763-1618转95911【官网】\n开发商案场限流 看房请务必提前来电预约登记\n请务必致电与销售确认时间，仅预约客户可进入销售现场，感谢您的支持\n深圳湾1号执笔者 | 盐田上新\n首推89套 | 半山精致住宅\n建面约88㎡-113㎡3房-4房\n山藏世界 海纳万境 启幕湾区理想生活\n\n\n深圳 鹏瑞半山云璟售楼处",
        "%&热搜情况%&": "523",
        "%&发布时间%&": "2023-08-19 14:29:11",
        "%&AI简述%&": "鹏瑞半山云璟官方售楼处电话|鹏瑞半山云璟地址|深圳鹏瑞半山云璟售楼中心发布",
        "%&来源%&": "赛居营销",
        "%&省%&": "四川省",
        "%&媒体%&": "网站",
        "%&作者%&": "智房网-名盘导购",
        "%&市%&": "成都市",
        "%&区%&": "武侯区",
      }
    };
  },
  methods: {
    lookIllustrate() {
      this.dialogVisible = true;
    },
    copy(text) {
      var textar = document.createElement("textarea");
      textar.value = text;
      document.body.appendChild(textar);
      textar.select(); // 选择对象
      document.execCommand("Copy"); // 执行浏览器复制命令
      textar.remove();
      this.$message({
        message: '复制成功！',
        type: 'success'
      });
    },
    previewCopy() {
      let text = this.textarea;
      for (const key in this.copyText) {
        text = text.replaceAll(key, this.copyText[key]);
      }
      this.textarea2 = text;
    },
    async getCustomCopy() {
      let data = { "organId": this.$store.state.user.organizationId };
      let res = await getCustomCopy(data);
      this.radio = res.data.data.isDefault ? res.data.data.isDefault : 0;
      this.textarea = res.data.data.content;
    },
    async customSave() {
      let data = {
        "organId": this.$store.state.user.organizationId,
        "isDefault": this.radio,
        "content": this.textarea
      };
      let res = await setCustomCopy(data);
      if (res.data.code == 200) {
        this.$message({
          message: '成功保存！',
          type: 'success'
        });
      }
    }
  },
  created() {
    this.getCustomCopy();
  }
};
</script>

<style scope>
.seven {
  width: 100%;
  min-height: 800px;
  background-color: #fff;
  border-radius: 0px 0px 4px 4px;
  margin-top: 16px;
  padding: 24px;
}

.seven-top {
  display: flex;
  align-items: center;
  position: relative;
}

.seven-top img {
  position: relative;
  top: 3px;
}

.seven-top .span1 {
  font-size: 16px;
  color: #333333;
}

.seven-top .span2 {
  font-size: 14px;
  color: #999999;
  margin-left: 8px;
}

.seven-top .seven-illustrate {
  position: absolute;
  right: 0;
  top: 0;
  width: 96px;
  height: 36px;
  background: #E9F4FF;
  border-radius: 4px;
  font-size: 16px;
  color: #2E59EC;
  text-align: center;
  line-height: 36px;
  cursor: pointer;
}

.p1 {
  font-size: 14px;
  color: #333333;
  margin-top: 25px;
  padding-left: 21px;
}

.radio {
  margin-top: 25px;
  padding-left: 21px;
}

.custom {
  width: 100%;
  margin-top: 40px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.custom-right {
  width: 750px;
}

.custom-left {
  width: 750px;
}

.custom-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 28px;
}

.custom-title span {
  font-size: 14px;
  color: #333333;
}

.custom-title div {
  width: 60px;
  height: 28px;
  background: linear-gradient(313deg, #2E59EC 0%, #1A91FF 100%);
  border-radius: 4px;
  font-size: 14px;
  color: #FFFFFF;
  text-align: center;
  line-height: 28px;
  cursor: pointer;
}

.custom-cont {
  width: 750px;
  height: 475px;
  margin-top: 16px;
  font-size: 14px;
  color: #333333;
  line-height: 20px;
}

.custom-right .custom-cont {
  background: #F4F5FA;
  border: none;
}

.custom-save {
  width: 72px;
  height: 36px;
  background: linear-gradient(313deg, #2E59EC 0%, #1A91FF 100%);
  border-radius: 4px;
  font-size: 16px;
  color: #FFFFFF;
  text-align: center;
  line-height: 36px;
  margin: 66px auto 0;
  cursor: pointer;
}

.table {
  width: 620px;
  background: #FFFFFF;
  border: 1px solid #E4E6EC;
}

.table-row {
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
}

.table-col {
  height: 100%;
  font-size: 14px;
  color: #333333;
  display: flex;
  align-items: center;
  padding-left: 20px;
}

.table-col span {
  width: 120px;
}

.table-col+.table-col {
  border-left: 1px solid #E4E6EC;
}

.table-col1 {
  width: 105px;
}

.table-col2 {
  width: 204px;
}

.grey-row {
  background: #F5F6FA;
}

.title-row .table-col {
  font-weight: bold;
}

.copy {
  margin-left: 10px;
  cursor: pointer;
}
</style>
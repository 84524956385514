<template>
  <div>
    <!-- 基本信息 -->
    <div class="essential first">
      <div class="content_top">
        <img class="content_top_img" :src="topqq" />
        <span class="content_top_font">基本信息</span>
      </div>
      <!-- 查看 -->
      <div class="essential_content">
        <el-row>
          <el-col :span="24">
            <span class="essential_content_tag">机构名称:</span>
            <span class="essential_content_text">{{xqList.organizationName}}</span>
          </el-col>
        </el-row>
        <el-row style="margin-top: 40px">
          <el-col :span="8">
            <span class="essential_content_tag">机构简称:</span>
            <span class="essential_content_text">{{xqList.abbreviation}}</span>
          </el-col>
          <el-col :span="8">
            <span class="essential_content_tag">上级机构:</span>
            <span class="essential_content_text">{{xqList.superiorName}}</span>
          </el-col>
          <el-col :span="8">
            <span class="essential_content_tag">机构类型:</span>
            <span class="essential_content_text">{{xqList.organizationTypeName}}</span>
          </el-col>
        </el-row>
        <el-row style="margin-top: 40px">
          <el-col :span="8">
            <span class="essential_content_tag">所属区域:</span>
            <span class="essential_content_text">{{xqList.affiliatedRegional}}</span>
          </el-col>
          <el-col :span="8">
            <span class="essential_content_tag">
              <span>销</span>
              <span style="margin-left:27px">售</span>:
            </span>
            <span class="essential_content_text">{{xqList.businessServices}}</span>
          </el-col>
          <el-col :span="8">
            <span class="essential_content_tag">服务期限:</span>
            <span class="essential_content_text">{{xqList.servicePeriod}}</span>
          </el-col>
        </el-row>
        <el-row style="margin-top: 40px">
          <el-col :span="8">
            <span class="essential_content_tag">
              <span>收</span>
              <span style="margin-left:7px">件</span>
              <span style="margin-left:8px">人:</span>
            </span>
            <span class="essential_content_text">{{xqList.recipients}}</span>
          </el-col>
          <el-col :span="8">
            <span class="essential_content_tag">联系电话:</span>
            <span class="essential_content_text">{{xqList.contactPhone}}</span>
          </el-col>
          <el-col :span="8">
            <span class="essential_content_tag">通讯地址:</span>
            <span class="essential_content_text">{{xqList.postalAddress}}</span>
          </el-col>
        </el-row>
      </div>
    </div>

    <!-- 管理员配置 -->
    <div class="essential">
      <div class="content_top">
        <img class="content_top_img" :src="topqq" />
        <span class="content_top_font">管理员</span>
      </div>
      <!-- 管理员 -->
      <div class="essential_table">
        <el-table
          :data="tableData"
          stripe
          :header-cell-style="headerStyle"
          :cell-style="{ textAlign: 'center' }"
          style="width: 100%; text-align: center"
        >
          <el-table-column prop="name" label="姓名"></el-table-column>
          <el-table-column prop="nickname" label="用户名(微信账号)"></el-table-column>
          <el-table-column prop="phoneNumber" label="手机号"></el-table-column>
          <el-table-column prop="customerEmail" label="邮箱"></el-table-column>
          <el-table-column prop="createTime" label="创建时间"></el-table-column>
          <el-table-column label="最后登录时间/IP">
            <template slot-scope="scope">
              <span v-show="scope.row.lastLoginTime != null">{{scope.row.lastLoginTime}}</span>
              <span v-show="scope.row.ip != null">{{ '/' +scope.row.ip}}</span>
            </template>
          </el-table-column>
          <el-table-column label="状态">
            <template slot-scope="scope">
              <span
                :class="[scope.row.accountStatus == '0' ? 'normal': 'shanchu']"
              >{{ scope.row.accountStatus == '0' ? "正常" : "禁用" }}</span>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <!-- 关键字配置  -->
    <div class="essential">
      <div class="content_top">
        <img class="content_top_img" :src="topqq" />
        <span class="content_top_font">关键字配置</span>
        <div
          class="content_top_icon"
          v-if="!keyword_disabled&&!$store.state.user.isVirtual"
          @click="keyEdit"
        >
          <img class="img_change" src="~@/assets/img/views/bj.png" />
          <span class="bianji">编辑</span>
        </div>
        <div v-if="keyword_disabled" class="content_top_icon content_top_button">
          <el-button size="medium" class="cancal_z" @click="keywordClose">取 消</el-button>
          <el-button size="medium" class="search_z" type="primary" @click="preservation">保存</el-button>
        </div>
      </div>
      <!-- 查看 -->
      <div v-if="!keyword_disabled" class="essential_content">
        <el-row>
          <el-col :span="24">
            <span class="essential_content_tag img_align">机构关键字:</span>
            <div class="keysBox">
              <div
                class="function_content"
                style="width:65%;display:inline-block;margin-bottom:10px"
                v-for="(item,index) in usedPhraseList"
                :key="index"
              >
                <div class="function_name">{{item.keyWordName}}</div>
                <div class="function_key" style="winth:100%">
                  <div>
                    <span style="font-size:14px;color:#999999">主：</span>
                    <span style="font-size:14px;color:#333333;margin-left:8px">{{item.mainKeyWord}}</span>
                  </div>
                  <div style="margin-top:8px">
                    <span style="font-size:14px;color:#999999">副：</span>
                    <span style="font-size:14px;color:#333333;margin-left:8px">{{item.subKeyWord}}</span>
                  </div>
                </div>
              </div>
            </div>
          </el-col>
        </el-row>
        <!-- <el-row style="margin-top: 40px">
          <el-col :span="24">
            <span class="essential_content_tag img_align">忽略关键字:</span>
            <span
              class="essential_content_text"
              v-for="(item, index) in keywordData.overlook"
              :key="index"
            >{{ item }}</span>
          </el-col>
        </el-row>
        <el-row style="margin-top: 40px">
          <el-col :span="24">
            <span class="essential_content_tag img_align">排除关键字:</span>
            <span
              class="essential_content_text"
              v-for="(item, index) in keywordData.exclude"
              :key="index"
            >{{ item }}</span>
          </el-col>
        </el-row> -->
      </div>
      <!-- 编辑 -->
      <div v-if="keyword_disabled" class="essential_content">
        <el-row>
          <el-col>
            <span class="essential_content_tag img_align">机构关键字:</span>
            <el-input
              style="width:280px;margin-right:10px"
              type="text"
              placeholder="词组名称"
              v-model="phraseObj.phraseName"
              maxlength="15"
              show-word-limit
            ></el-input>

            <el-input
              style="width:350px;margin-right:10px"
              v-model="phraseObj.minKeys"
              placeholder="设置主关键字"
            ></el-input>

            <el-input style="width:350px" v-model="phraseObj.Fukeys" placeholder="设置副关键字"></el-input>
            <span @click="addDataList" class="addList">添加</span>
            <!-- 。。。。。自己手动添加的关键字 -->
            <div class="listFor" v-for="(item,ind) in phraseList" :key="ind">
              <div class="function_content" style="width:65%;margin-left:99px;margin-top:16px">
                <div class="function_name">{{item.keyWordName}}</div>
                <div class="function_key">
                  <div>
                    <span style="font-size:14px;color:#999999">主：</span>
                    <span style="font-size:14px;color:#333333;margin-left:8px">{{item.mainKeyWord}}</span>
                  </div>
                  <div style="margin-top:8px">
                    <span style="font-size:14px;color:#999999">副：</span>
                    <span style="font-size:14px;color:#333333;margin-left:8px">{{item.subKeyWord}}</span>
                  </div>
                </div>
              </div>
              <div @click="deleteData(1,ind,1)" class="delete">删除</div>
            </div>
            <!-- 。。。。自己手动添加的关键字 -->
            <!-- 后台返回关键字 -->
            <div class="listFor" v-for="(item,index) in usedPhraseListEdit" :key="index+'a'">
              <div class="function_content" style="width:65%;margin-left:99px;margin-top:16px">
                <div class="function_name">{{item.keyWordName}}</div>
                <div class="function_key">
                  <div>
                    <span style="font-size:14px;color:#999999">主：</span>
                    <span style="font-size:14px;color:#333333;margin-left:8px">{{item.mainKeyWord}}</span>
                  </div>
                  <div style="margin-top:8px">
                    <span style="font-size:14px;color:#999999">副：</span>
                    <span style="font-size:14px;color:#333333;margin-left:8px">{{item.subKeyWord}}</span>
                  </div>
                </div>
              </div>
              <div @click="deleteData(2,item.keyWordId,index)" class="delete">删除</div>
            </div>
            <!-- 后台返回关键字 -->
          </el-col>
        </el-row>
        <!-- <el-row style="margin-top: 40px">
          <el-col :span="24">
            <span class="essential_content_tag img_align">忽略关键字:</span>
            <el-input
              class="img_align"
              style="width: 1000px"
              type="textarea"
              placeholder="关键字请用空格隔开"
              v-model="keywordData1.ignoringKeywords"
            ></el-input>
          </el-col>
        </el-row>
        <el-row style="margin-top: 40px">
          <el-col :span="24">
            <span class="essential_content_tag img_align">排除关键字:</span>
            <el-input
              class="img_align"
              style="width: 1000px"
              type="textarea"
              placeholder="关键字请用空格隔开"
              v-model="keywordData1.excludeKey"
            ></el-input>
          </el-col>
        </el-row> -->
      </div>
      <div class="keyword_title_box" style="padding-left:30px">
        <!-- 所属区域 -->
        <div
          v-if="($store.state.user.broadHeading == '一类' || $store.state.user.broadHeading == '二类')&&this.xqList.regionType === 1"
        >
          <div class="keyword_title">
            <span class="content_top_font" style="margin: 0">所属区域关键字</span>
            <div class="zhankai-change" @click="zhankaiChange">
              <span class="xia-span">{{ zhankai ? "收起" : "展开" }}</span>
              <span>
                <img
                  class="xia-span-img"
                  src="../../../assets/img/views/more.png"
                  alt
                  v-show="zhankai"
                />
                <img src="../../../assets/img/views/xia.png" alt v-show="!zhankai" class="xia-img" />
              </span>
            </div>
          </div>
          <div v-if="keyWordShow">
            <div v-for="(itt,indd) in keyWordListBox" :key="indd">
              <div class="content-box">
                <div class="content-box-text">{{itt.placeName}}关键字组：</div>
                <div style="width:100%">
                  <div
                    class="function_content"
                    v-for=" (item,index) in itt.keyWordList"
                    :key="index"
                  >
                    <div class="function_name">{{item.keyWordName}}</div>
                    <div class="function_key">
                      <div>
                        <span style="font-size:14px;color:#999999">主：</span>
                        <span
                          style="font-size:14px;color:#333333;margin-left:8px"
                        >{{item.mainKeyWord}}</span>
                      </div>
                      <div style="margin-top:8px">
                        <span style="font-size:14px;color:#999999">副：</span>
                        <span
                          style="font-size:14px;color:#333333;margin-left:8px"
                        >{{item.subKeyWord}}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- <div v-if="itt.ignoringKeywords" class="function_content_child">
                <span class="essential_content_tag">{{itt.placeName}}忽略关键字 :</span>
                <span
                  class="essential_content_text"
                >{{ itt.ignoringKeywords?itt.ignoringKeywords:'' }}</span>
              </div>
              <div v-if="itt.excludeKey" class="function_content_child">
                <span class="essential_content_tag">{{itt.placeName}}排除关键字 :</span>
                <span class="excludeKey_text">{{itt.excludeKey?itt.excludeKey:''}}</span>
              </div> -->
            </div>
          </div>
        </div>
        <!-- 机构类型 关键字 -->
        <div v-if="$store.state.user.broadHeading == '二类'">
          <div class="keyword_title">
            <span class="content_top_font" style="margin: 0">机构类型关键字</span>
          </div>
          <div class="function_content_child">
            <span class="essential_content_tag">关键字设置 :</span>
            <span
              class="essential_content_text"
              v-for="(item, index) in regionData.keywordSetting"
              :key="index"
            >{{ item }}</span>
          </div>
          <!-- <div class="function_content_child">
            <span class="essential_content_tag">忽略关键字 :</span>
            <span
              class="essential_content_text"
              v-for="(item, index) in regionData.ignoringKeywords"
              :key="index"
            >{{ item }}</span>
          </div>
          <div class="function_content_child">
            <span class="essential_content_tag">排除关键字 :</span>
            <span
              class="essential_content_text"
              v-for="(item, index) in regionData.excludeKey"
              :key="index"
            >{{ item }}</span>
          </div> -->
        </div>
      </div>
    </div>

    <!-- 预警模型 -->
    <div class="warning_moudle essential yujing" ref="six">
      <div class="content_top">
        <img class="content_top_img" :src="topqq" />
        <span class="content_top_font">预警模型</span>
        <!-- 查看 -->
        <!-- <div class="content_top_icon" v-if="!warning_disabled" @click="handleEdit()">
          <img class="img_change" src="~@/assets/img/views/bj.png" />
          <span class="bianji">编辑</span>
        </div>-->
        <!-- <div  class="content_top_icon content_top_button">
          <el-button size="medium" class="cancal_z" @click="warning_disabled = false">取 消</el-button>
          <el-button size="medium" class="search_z" type="primary" @click="changeYes()">保存</el-button>
        </div>-->
      </div>
      <!-- 查看 -->
      <div v-if="!warning_disabled && functionDataShow" class="essential_content">
        <div class="warning_moudle_time">
          <el-tooltip
            class="item"
            effect="dark"
            content="在设定时段内，移动端收到新消息会响铃或震动"
            placement="bottom-start"
          >
            <span class="essential_content_tag">
              提醒时段
              <img class="essential_content_hint" src="../../../assets/img/views/hint.png" alt />:
            </span>
          </el-tooltip>
          <!-- 预警模型！！！！！ -->
          <span class="essential_content_text">
            {{
            functionData.warnReceptionStime == null
            ? "--"
            : functionData.warnReceptionStime
            }}
            至
            {{
            functionData.warnReceptionEtime == null
            ? "--"
            : functionData.warnReceptionEtime
            }}
          </span>
        </div>
        <div class="warning_moudle_content">
          <div class="warning_tone">
            <span class="essential_content_tag">预警调性:</span>
            <span
              class="essential_content_text"
              v-for="(item, index) in functionData.warnType"
              :key="index"
            >
              <span v-if="item != ''">{{item}}</span>
              <!-- <span v-else>无</span> -->
            </span>
          </div>
          <div class="Warning_events">
            <span class="essential_content_tag warning_moudle_tag">预警事件:</span>
            <div class="tree_wrap">
              <div class="tree_top">
                <span style="font-size:14px;color:#333333;font-weight: bold;">
                  全部事件({{ functionDataRuleForm.CheckedNodes.length }})
                </span>
              </div>
              <div v-loading="loading" class="tree_content">
                <el-tree
                  :load="loadNode2"
                  lazy
                  node-key="eventId"
                  ref="tree"
                  :props="defaultProps"
                  :default-expand-all="true"
                ></el-tree>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 编辑 -->
      <!-- <div v-if="warning_disabled" class="essential_content">
        <div class="warning_moudle_time">
          <el-tooltip
            class="item"
            effect="dark"
            content="在设定时段内，移动端收到新消息会响铃或震"
            placement="bottom-start"
          >
            <span class="essential_content_tag">
              提醒时段
              <img class="essential_content_hint" src="../../../assets/img/views/hint.png" alt />:
            </span>
          </el-tooltip>
          <el-time-picker
            style="width: 280px"
            is-range
            v-model="value1"
            range-separator="一"
            format="HH:mm"
            value-format="HH:mm"
            start-placeholder="开始时间"
            end-placeholder="结束时间"
            placeholder="选择时间范围"
          ></el-time-picker>
        </div>
        <div class="warning_moudle_content">
          <div class="warning_tone">
            <span class="essential_content_tag">预警调性:</span>
            <el-checkbox-group style="display: inline-block" v-model="checkList">
              <el-checkbox label="正面" style="margin-right: 80px"></el-checkbox>
              <el-checkbox label="负面" style="margin-right: 80px"></el-checkbox>
              <el-checkbox label="中性"></el-checkbox>
            </el-checkbox-group>
          </div>
          <div class="Warning_events">
            <span class="essential_content_tag warning_moudle_tag">预警事件:</span>
            <div class="tree_wrap">
              <div class="tree_top">
                <el-checkbox v-model="checked" @change="setCheckedKeys">全部事件</el-checkbox>
              </div>
              <el-tree
                :load="loadNode"
                lazy
                ref="tree"
                node-key="eventId"
                :props="defaultProps"
                @check-change="treeChange"
                :default-checked-keys="ruleForm.CheckedNodes"
                :default-expand-all="true"
                show-checkbox
              ></el-tree>
            </div>
          </div>
        </div>
      </div>-->
    </div>
    <!-- 预警模型接收设置 -->
    <modelSet v-if="warningCom" :warningCom="warningCom"></modelSet>
  </div>
</template>

<script>
import {
  xqList,
  adminList,
  // organizaKeyword,
  organizaKeywordNew,
  keyWordAddNew,
  getInfo,
  findById,
  subordinatekeys
} from "../../../api/setting/list";
import topqq from "@/assets/img/views/juzhong.png";
import modelSet from "../component/modelSet";
export default {
  data() {
    return {
      //...... 机构关/键字配置 编辑
      phraseObj: {
        phraseName: "",
        minKeys: "",
        Fukeys: ""
      },

      // 添加的新值数组
      phraseList: [],
      // 旧值数组
      usedPhraseList: [],
      // 编辑时的旧值数组
      usedPhraseListEdit: [],
      // 保存删除的机构关键字ID 只对于历史关键字、
      deleteList: [],
      //..... 机构关键字配置 编辑
      loading: false,
      // 需要给预警模型设置传
      warningCom: "",
      // 存放信息的对象
      xqList: {},
      topqq: topqq,
      checked: false,
      // 关键字
      keyword_disabled: false,
      // 预警模型
      warning_disabled: false,
      warning_dialog_look: false,
      value1: "",
      headerStyle: {
        background: "#F6F7FA",
        color: "#333",
        textAlign: "center"
      },
      // 预警模型接收设置

      tableData: [],
      functionDataShow: false,
      imageUrl: "",
      // 关键字配置
      // 查看的时候
      keywordData: {
        organization: [],
        overlook: [],
        exclude: []
      },
      // 提交的时候
      keywordData1: {
        ignoringKeywords: "",
        excludeKey: ""
      },
      regionData: {
        // 关键词设置
        keywordSetting: [],
        // 忽略关键字
        ignoringKeywords: [],
        // 排除键
        excludeKey: []
      },
      // 存放关键字转化成&符号后的对象
      obj: {},
      //编辑下的 时间
      time: null,
      // 编辑下的预警事件
      functionDataRuleForm: {
        HalfCheckedNodes: [], // 事件id
        CheckedNodes: []
      },
      ids: [],
      defaultProps: {
        label: "eventName",
        id: "eventId"
      },
      // 基本信息
      data1: {
        name1:
          "西安市高新区管委会西安市高新区管委会西安市高新区管委会西安市高新区管委会",
        name2: "李珊"
      },
      checkList: [],
      msg: "",
      // 编辑下的预警事件
      ruleForm: {
        HalfCheckedNodes: [], // 事件id
        CheckedNodes: []
      },
      // 机构简称
      abbreviation: "",
      // 机构大类
      broadHeading: "",
      keyWordList: [],
      // 排除关键字
      excludeKey: "",
      // 忽略关键字
      ignoringKeywords: "",
      zoneCode: "",
      // 关键字整体数据
      keyWordListBox: [],
      // 是否展开收起
      zhankai: false,
      indexFlag: 0,
      // 关键字组是否展示
      keyWordShow: false
    };
  },
  methods: {
    // 获取详情
    // 获取详情
    getTreeData() {
      this.$http({
        method: "get",
        url: "/api/organization/warnmodel/getWarnInfo",
        params: {
          organizationId: this.$store.state.user.organizationId
        }
      })
        .then(res => {
          if (res.data.code == 200) {
            if (res.data.data) {
              this.warningCom = res.data.data.warnType;
              res.data.data.warnType = res.data.data.warnType.split(",");
              this.functionData = res.data.data;
              this.functionDataRuleForm.HalfCheckedNodes = JSON.parse(
                res.data.data.eventId
              ).HalfCheckedNodes;
              this.functionDataRuleForm.CheckedNodes = JSON.parse(
                res.data.data.eventId
              ).CheckedNodes;
              if (this.ids == this.functionDataRuleForm.CheckedNodes) {
                this.checked = true;
              }
              if (
                this.functionData.warnReceptionStime != null &&
                this.functionData.warnReceptionStime != ""
              ) {
                this.time = [
                  this.functionData.warnReceptionStime,
                  this.functionData.warnReceptionEtime
                ];
              } else {
                this.time = null;
              }
              this.checkList = this.functionData.warnType;
              this.functionDataShow = true;
            }
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    // 展开 收缩 头部
    zhankaiChange() {
      // 机构关键字配置
      if (!this.zhankai && this.indexFlag == 0) {
        this.keyWordShow = true;
        this.getInfo(this.$store.state.user.areaId);
      } else if (this.zhankai) {
        this.keyWordShow = false;
      } else if (!this.zhankai) {
        this.keyWordShow = true;
      }
      this.indexFlag++;
      this.zhankai = !this.zhankai;
    },
    // 机构管理关键字xiangqing
    async findById() {
      const res = await findById({
        organizationTypeId: this.$store.state.user.organizationTypeId
      });
      if (res.data) {
        if (res.data.data) {
          if (res.data.data.keywordSetting) {
            this.regionData.keywordSetting = res.data.data.keywordSetting.split(
              " "
            );
          }
          if (res.data.data.ignoringKeywords) {
            this.regionData.ignoringKeywords = res.data.data.ignoringKeywords.split(
              " "
            );
          }
          if (res.data.data.excludeKey) {
            this.regionData.excludeKey = res.data.data.excludeKey.split(" ");
          }
        }
      }
    },
    // 编辑情况下机构关键字添加
    addDataList() {
      if (!this.phraseObj.phraseName.trim() || !this.phraseObj.minKeys.trim()) {
        this.$message.error(" 词组名称或主关键字不能为空!");
        if (!this.phraseObj.phraseName.trim()) {
          this.phraseObj.phraseName = "";
        }
        return false;
      }
      let num = this.phraseList.length + this.usedPhraseListEdit.length;
      if (num >= this.$store.state.user.keywordLimit) {
        this.$message.warning("关键字组数量不能超过最大限制！");
        this.phraseObj = {
          phraseName: "",
          minKeys: "",
          Fukeys: ""
        };
        return false;
      }

      let obj = {
        keyWordName: this.phraseObj.phraseName,
        mainKeyWord: this.phraseObj.minKeys,
        subKeyWord: this.phraseObj.Fukeys,
        organizationId: this.$store.state.user.organizationId
      };
      this.phraseObj = {
        phraseName: "",
        minKeys: "",
        Fukeys: ""
      };
      this.phraseList.unshift(obj);
    },
    // 编辑时关键字删除
    deleteData(type, data, num) {
      if (type == 1) {
        // 说明删除的是自己手动添加的关键字
        this.phraseList.splice(data, 1);
      } else {
        this.usedPhraseListEdit.splice(num, 1);
        this.deleteList.indexOf(data) == -1 ? this.deleteList.push(data) : "";
      }
    },
    // 关键字取消编辑
    keywordClose() {
      this.organizaKeyword();
      this.deleteList = [];
      this.phraseList = [];
      this.keyword_disabled = false;
    },
    // areaIdList() {},
    // 树节点的选择
    setCheckedKeys() {
      if (this.checked == true) {
        this.$refs.tree.setCheckedKeys(this.ids);
      } else {
        this.$refs.tree.setCheckedKeys([]);
      }
    },
    //编辑下选择
    treeChange() {
      let HalfCheckedNodes = [];
      let CheckedNodes = [];
      this.$refs.tree.getCheckedNodes().forEach(x => {
        CheckedNodes.push(x.eventId);
      });
      this.$refs.tree.getHalfCheckedNodes().forEach(x => {
        HalfCheckedNodes.push(x.eventId);
      });
      this.ruleForm.HalfCheckedNodes = HalfCheckedNodes;
      this.ruleForm.CheckedNodes = CheckedNodes;
    },
    //编辑的懒加载树状图
    loadNode(node, resolve) {
      if (node.level === 0) {
        this.$http({
          method: "post",
          url: "/api/system/event/list",
          data: {}
        })
          .then(res => {
            if (res.data.code == 200) {
              res.data.data.forEach(x => {
                this.ids.push(x.eventId);
              });
              resolve(res.data.data);
            }
          })
          .catch(error => {
            console.log(error);
          });
      }
      if (node.level >= 1) {
        this.$http({
          method: "post",
          url: "/api/system/event/list",
          data: {
            eventId: node.data.eventId
          }
        })
          .then(res => {
            if (res.data.code == 200) {
              resolve(res.data.data);
              this.$refs.tree.setCheckedKeys(
                this.functionDataRuleForm.CheckedNodes
              );
            }
          })
          .catch(error => {
            console.log(error);
          });
      }
    },
    // 查看下的懒加载树状图
    loadNode2(node, resolve) {
      this.loading = true;
      let dataAll = [
        this.functionDataRuleForm.HalfCheckedNodes,
        this.functionDataRuleForm.CheckedNodes
      ].flat(Infinity);
      if (node.level === 0) {
        this.$http({
          method: "post",
          url: "/api/system/event/list",
          data: { parentId: 1 }
        })
          .then(res => {
            if (res.data.code == 200) {
              let data = [];
              dataAll.forEach(x => {
                res.data.data.forEach(n => {
                  if (x == n.eventId) {
                    data.push(n);
                  }
                });
              });
              resolve(data);
            }
          })
          .catch(error => {
            console.log(error);
          });
      }
      if (node.level >= 1 && node.level <= 2) {
        dataAll.forEach(x => {
          if (x == node.data.eventId) {
            this.$http({
              method: "post",
              url: "/api/system/event/list",
              data: {
                parentId: node.data.eventId
              }
            })
              .then(res => {
                if (res.data.code == 200) {
                  let data = [];
                  dataAll.forEach(x => {
                    res.data.data.forEach(n => {
                      if (x == n.eventId) {
                        data.push(n);
                      }
                    });
                  });
                  resolve(data);
                }
              })
              .catch(error => {
                console.log(error);
              });
          }
        });
      } else {
        resolve([]);
      }
      this.loading = false;
    },
    // 获取基本信息
    async getXqList() {
      const res = await xqList({
        organizationId: this.$store.state.user.organizationId
      });
      this.abbreviation = res.data.data.abbreviation;
      this.broadHeading = res.data.data.broadHeading;
      this.xqList = res.data.data;
    },

    async adminList() {
      let res = await adminList({
        organizationId: this.$store.state.user.organizationId
      });
      this.tableData = res.data.rows;
    },
    //关键字配置详情
    async organizaKeyword() {
      this.usedPhraseList = [];
      const res = await organizaKeywordNew({
        organizationId: this.$store.state.user.organizationId
      });
      if (res != undefined) {
        this.msg = res.data.msg;
        if (res.data.data != undefined) {
          this.obj = {};
          this.obj = res.data.data;
          this.obj.keyWordList.forEach(item => {
            let obj = {
              keyWordId: item.keyWordId,
              keyWordName: item.keyWordName,
              mainKeyWord: item.mainKeyWord,
              subKeyWord: item.subKeyWord
            };
            this.usedPhraseList.push(obj);
            this.usedPhraseListEdit.push(obj);
          });
          if (this.obj.ignoringKeyword) {
            this.keywordData.overlook = this.obj.ignoringKeyword.split(" ");
          } else {
            this.keywordData.overlook = [];
          }
          if (this.obj.excludeKey) {
            this.keywordData.exclude = this.obj.excludeKey.split(" ");
          } else {
            this.keywordData.exclude = [];
          }
          this.keywordData.organization.forEach((item, index) => {
            if (item == "") {
              this.keywordData.organization.splice(index, 1);
            }
          });
          this.keywordData.overlook.forEach((item, index) => {
            if (item == "") {
              this.keywordData.overlook.splice(index, 1);
            }
          });
          this.keywordData.exclude.forEach((item, index) => {
            if (item == "") {
              this.keywordData.exclude.splice(index, 1);
            }
          });
        }
      }
    },
    // 关键字保存的时候
    async preservation() {
      this.keyword_disabled = false;
      let data = {};
      data.keyWordList = this.phraseList;
      data.ignoringKeyword = this.keywordData1.ignoringKeywords;
      data.excludeKey = this.keywordData1.excludeKey;
      data.organizationId = this.$store.state.user.organizationId;
      data.removeList = this.deleteList;
      data.organizationName = this.$store.state.user.mechanism;
      data.abbreviation = this.$store.state.user.abbreviation;
      data.broadHeading = this.$store.state.user.broadHeading;
      data.type = 0;
      const res = await keyWordAddNew(data);
      if (res.data.msg == "操作成功") {
        this.$message({
          message: "操作成功！",
          duration: 5000,
          type: "success"
        });
      } else {
        this.$message({
          message: "操作失败！",
          duration: 5000,
          type: "error"
        });
      }
      this.phraseList = [];

      this.organizaKeyword();
    },
    // 关键字编辑
    keyEdit() {
      this.keyword_disabled = true;
      this.usedPhraseListEdit = this.usedPhraseList;
      this.keywordData1.ignoringKeywords = this.obj.ignoringKeyword;
      this.keywordData1.excludeKey = this.obj.excludeKey;
    },
    // 关键字配置所属区域关键字
    async getInfo(areaIds) {
      const res = await getInfo({
        areaId: areaIds
      });
      this.keyWordListBox.push(res.data.data);
      if (res.data.data.zoneCode) {
        this.subordinatekeys(res.data.data.zoneCode);
      }
    },
    // 查询下级区域
    async subordinatekeys(zoneCodes) {
      const res = await subordinatekeys({
        zoneCode: zoneCodes
      });
      if (res.data.data.length != 0) {
        res.data.data.forEach(item => {
          this.getInfo(item.areaId);
        });
      }
    },
    // 提交表单
    changeYes() {
      if (this.time == null) {
        this.$message({
          message: "请选择时间",
          type: "warning"
        });
        return false;
      }
      if (this.checkList.length == 0) {
        this.$message({
          message: "请选择预警调性",
          type: "warning"
        });
        return false;
      }
      let data = {};
      data.organizationId = this.$store.state.user.organizationId;
      data.eventId = JSON.stringify(this.ruleForm);
      data.warnType = this.checkList.join(",");
      data.warnReceptionStime = this.time[0];
      data.warnReceptionEtime = this.time[1];
      this.$http({
        method: "post",
        url: "/api/organization/warnmodel/add",
        data: data
      })
        .then(res => {
          if (res.data.code == 200) {
            this.$message({
              message: "编辑成功",
              type: "success"
            });
            this.getTreeData();
            this.warning_disabled = false;
          }
        })
        .catch(err => {
          console.log(err);
        });
    }
  },
  components: {
    modelSet
  },
  // 管理员
  created() {
    this.getXqList();
    this.adminList();
    this.organizaKeyword();
    this.getTreeData();
    if (this.$store.state.user.broadHeading == "二类") {
      this.findById();
    }
  }
};
</script>
<style scoped>
.top_img,
.top_font {
  display: inline-block;
  vertical-align: middle;
}

.top_img {
  cursor: pointer;
}

.top_font {
  font-size: 20px;
  font-weight: bold;
  color: #333333;
  margin: 0 24px 0 16px;
}

.essential {
  background: #ffffff;
  border-radius: 4px;
  padding: 24px;
  margin-top: 16px;
}

.content_top {
  overflow: hidden;
  height: 36px;
}

.content_top_font,
.content_top_icon,
.content_top_img {
  display: inline-block;
  vertical-align: bottom;
}

.content_top_img {
  position: relative;
  top: 2px;
}

.img_change {
  position: relative;
  top: 3px;
}
.content_top_font {
  font-size: 16px;
  font-weight: bold;
  color: #333333;
  margin-left: 5px;
}

.content_top_icon {
  margin-left: 24px;
  cursor: pointer;
}

.content_top_button {
  float: right;
}

.bianji {
  font-size: 14px;
  color: #2e59ec;
  margin-left: 10px;
  padding: 0;
}

.essential_content {
  padding: 20px;
  margin-top: 28px;
}
.yujing-Model .essential_content {
  padding-top: 0;
  margin-top: 0;
}
.essential_content_tag {
  font-size: 14px;
  font-weight: bold;
  color: #333333;
  margin-right: 16px;
}
.essential_content .essential_content_tag {
  display: inline-block;
  width: 83px;
  text-align: right;
}

.essential_content_text {
  font-size: 14px;
  color: #666666;
  margin-right: 40px;
}
.essential_table {
  margin-top: 24px;
}

.bianji,
.table_line,
.shanchu {
  display: inline-block;
  vertical-align: middle;
}

.table_line {
  width: 1px;
  height: 14px;
  background: #e4e6ec;
  margin: 0 16px;
}

.shanchu {
  color: #ea3342;
}

.function_content {
  display: flex;
  background: #f6faff;
  width: 100%;
  padding: 16px;
  margin-bottom: 2px;
  border-radius: 6px;
}
.function_content > div {
  display: inline-block;
  vertical-align: top;
}
.function_content_child {
  margin-top: 40px;
  margin-bottom: 40px;
}
.keyword_title_box .function_content :last-child {
  width: 1300px;
  margin-bottom: 0;
}

.keyword_title {
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
}

.warning_tone {
  margin: 40px 0;
}
.tree_wrap {
  border: 1px solid #d8d8d8;
}
.yujing .tree_wrap {
  display: inline-block;
  vertical-align: top;
  width: 600px;
  height: 470px;
  margin-right: 62px;
}
.warning_dialog_margin .tree_wrap {
  display: inline-block;
  vertical-align: top;
  width: 600px;
  height: 470px;
  margin-right: 62px;
}
.warning_moudle_tag {
  display: inline-block;
  vertical-align: top;
}

.tree_top {
  width: 100%;
  padding: 12px 24px;
  border-bottom: solid 1px #d8d8d8;
}
.tree_content {
  padding: 24px;
}
.tree_content {
  height: 410px;
  overflow-y: auto;
}
.tree_content {
  height: 420px;
  overflow-y: auto;
}
.img_align {
  display: inline-block;
  vertical-align: top;
}

.dialog_margin {
  margin-top: 10px;
}
.warning_dialog_margin {
  margin-bottom: 20px;
}

.warning_dialog_space {
  margin: 20px 0;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 80px;
  height: 80px;
  line-height: 80px;
  text-align: center;
  background: #fff;
}

.avatar-uploader,
.el-image {
  display: inline-block;
  vertical-align: middle;
}

.avatar-uploader {
  margin-right: 24px;
}
.tagsRegion {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  top: 10px;
  margin-left: 110px;
}
.el-table::before {
  height: 0;
}
.normal {
  color: #02bc7c;
}
.essential_content_hint {
  margin: 0 3px;
  position: relative;
  top: 1px;
}
.warning_moudle_time {
  display: flex;
  align-items: center;
}
.content-box {
  margin-top: 24px;
  display: flex;
}
.content-box-text {
  width: 130px;
  font-weight: 600;
  color: #333333;
  font-size: 14px;
}
.function_name {
  margin-right: 15px;
  font-weight: 600;
  color: #333333;
  font-size: 14px;
}
.addList {
  margin-left: 10px;
  font-size: 16px;
  font-weight: 600;
  color: #2e59ec;
  cursor: pointer;
}
.keysBox {
  display: inline-block;
  width: 80%;
}
.listFor > div {
  display: inline-block;
}
.listFor {
  display: flex;
  align-items: center;
}
.listFor .delete {
  margin-left: 10px;
  font-size: 14px;
  color: #ea3342;
  cursor: pointer;
}
.excludeKey_text {
  font-size: 14px;
  color: #666666;
}
.zhankai-change {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.zhankai-change span {
  display: flex;
  align-items: center;
}
.xia-span {
  font-size: 14px;
  color: #2e59ec;
  width: 41px;
}

.xia-span-img {
  transform: rotate(-90deg);
}
</style>
<template>
  <div class="setting-shoucang timeMessage">
    <div class="organization_detail_nav">
      <el-tabs v-model="active" @tab-click="goAssignBlock(active)">
        <el-tab-pane label="境内收藏" name="first" v-if="$store.state.user.scope[0]"></el-tab-pane>
        <el-tab-pane label="境外收藏" name="second" v-if="$store.state.user.scope[1]"></el-tab-pane>
      </el-tabs>
    </div>
    <!-- 搜索框 -->
    <div class="organizationManagement_top">
      <div class="organizationManagement_top_content">
        <div class="el_search">
          <div class="el_search_input">
            <el-select class="select" v-model="search.region">
              <el-option label="标题" value="title"></el-option>
              <el-option v-if="active == 'first'" label="正文" value="abstracts"></el-option>
              <el-option v-if="active == 'second'" label="正文" value="abstractTraslation"></el-option>
              <el-option label="作者" value="author"></el-option>
              <el-option label="来源" value="source"></el-option>
            </el-select>
            <el-input
              clearable
              style="width: 240px;"
              placeholder="信息标题/正文/作者模糊搜索"
              v-model="search.input1"
            >
              <i slot="prefix" class="el-input__icon el-icon-search"></i>
            </el-input>
            <span class="el_font">查询时间</span>
            <!-- <el-date-picker
              value-format="yyyy-MM-dd HH:mm:ss"
              size="medium"
              class="picker-data"
              @focus="focusDate"
              v-model="search.timer"
              type="datetimerange"
              range-separator="—"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            ></el-date-picker> -->
            <el-date-picker
              v-model="search.timer[0]"
              type="datetime"
              size="medium"
              value-format="yyyy-MM-dd HH:mm:ss"
              placeholder="开始日期">
            </el-date-picker>
            -
            <el-date-picker
              v-model="search.timer[1]"
              type="datetime"
              size="medium"
              value-format="yyyy-MM-dd HH:mm:ss"
              placeholder="结束日期">
            </el-date-picker>
            <span v-show="showing" class="el_font">事件</span>
            <el-cascader
              v-show="showing"
              placeholder="请选择事件"
              :props="props"
              clearable
              v-model="search.event"
            ></el-cascader>
          </div>
          <div class="el_search_button">
            <el-button type="primary" size="medium" @click="queryLook">查询</el-button>
            <el-button size="medium" @click="resetNum">重置</el-button>
          </div>
        </div>
      </div>
    </div>
    <!-- 内容部分 -->
    <component :type="type" :is="active" ref="labelJing"></component>
  </div>
</template>

<script>
import first from "../component/jingnei.vue";
import second from "../component/jingwai.vue";
import { getIncident } from "../../../api/message/list";

export default {
  data() {
    return {
      showing: true,
      active: sessionStorage.getItem("active")
        ? sessionStorage.getItem("active")
        : "",
      // 搜索条件
      search: {
        region: "title",
        input1: "",
        timer: [],
        event: ""
      },
      type: 2,
      props: {
        lazy: true,
        checkStrictly: true,
        async lazyLoad(node, resolve) {
          const { level } = node;
          let data = {};
          if (node.level == 0) {
            data.parentId = 1;
          }
          if (node.level != 0) {
            data = {
              parentId: node.data.eventId
            };
          }
          if (node.level < 3) {
            const res = await getIncident(data);
            if (res != undefined) {
              if (res.data.data.length == 0) {
                node.data.leaf = true;
                return resolve([]);
              } else {
                const nodes = res.data.data.map(item => ({
                  value: item,
                  label: item.eventName,
                  eventId: item.eventId,
                  leaf: level >= 10
                }));
                resolve(nodes);
              }
            }
          } else {
            node.data.leaf = true;
            return resolve([]);
          }
        }
      }
    };
  },
  components: {
    first,
    second
  },
  methods: {
    // 点击导航栏切换的时候
    goAssignBlock(el) {
      sessionStorage.setItem("active", el);
      if (el == "second") {
        this.showing = false;
      } else {
        this.showing = true;
      }
      // 搜索条件
      this.search = {
        region: "title",
        input1: "",
        timer: [],
        event: ""
      };
    },
    // 时间框获得焦点
    focusDate() {
      this.search.timer = [];
      this.search.timer[0] =
        this.$calcu.timeCycle(new Date(), "yyyy-MM-dd") + " " + "00:00:00";
      this.search.timer[1] = this.$calcu.timeCycle(
        new Date(),
        "yyyy-MM-dd HH:mm:ss"
      );
    },
    // 查询事件-条件
    queryLook() {
      let data = {};
      if(this.search.timer[0]&&this.search.timer[1]&&this.search.timer[0]>=this.search.timer[1]){
        this.$message.warning("请选择正确的时间段");
        return;
      }
      if(!this.search.timer[0]||!this.search.timer[1]){
        this.search.timer = [];
      }
      if (this.search.input1 != "") {
        data[this.search.region] = this.search.input1;
      }
      // 事件 search.event
      if (this.search.event.length != 0) {
        data.eventClass = this.search.event[
          this.search.event.length - 1
        ].eventName;
      }
      if (this.search.timer && this.search.timer[1] && this.search.timer[0]) {
        //时间
        this.search.timer[0]
          ? (data.startTimeStr =
              this.search.timer[0].replace(" ", "T") + "+08:00")
          : false;
        this.search.timer[1]
          ? (data.endTimeStr =
              this.search.timer[1].replace(" ", "T") + "+08:00")
          : false;
      }
      this.$refs.labelJing.getListInof(data, "search");
    },
    // 重置事件
    resetNum() {
      this.search = {
        region: "title",
        input1: "",
        timer: [],
        event: ""
      };
      this.endTime = "";
      this.startTime = "";
      this.$refs.labelJing.resetNum();
    }
  },
  created() {
    if (this.$store.state.user.scope[0]) {
      this.active = sessionStorage.getItem("active")
        ? sessionStorage.getItem("active")
        : "first";
    } else if (
      !this.$store.state.user.scope[0] &&
      this.$store.state.user.scope[1]
    ) {
      this.active = sessionStorage.getItem("active")
        ? sessionStorage.getItem("active")
        : "second";
    }
    if (this.active == "second") {
      this.showing = false;
    } else {
      this.showing = true;
    }
  }
};
</script>

<style scope>
.setting-shoucang .organization_detail_nav {
  padding-left: 34px;
  background: #fff;
  margin-top: 16px;
  border-radius: 4px;
}
.setting-shoucang .organization_detail_nav .el-tabs__item {
  font-size: 16px;
  font-weight: bold;
}
.setting-shoucang .organizationManagement_top {
  background: #ffffff;
  border-radius: 4px;
}

.setting-shoucang .organizationManagement_top_content {
  border-top: 1px solid #d8d8d8;
  padding: 23px 24px;
}
.setting-shoucang .el_search {
  display: flex;
  justify-content: space-between;
}

.setting-shoucang .el_search_input {
  float: left;
}

.setting-shoucang .el_search_button .el-button {
  width: 80px;
  height: 36px;
}
.setting-shoucang .el_font {
  margin-left: 32px;
  margin-right: 12px;
  color: #333333;
  font-weight: bold;
  font-size: 14px;
}
.setting-shoucang .el_search_button .el-button {
  width: 80px;
  height: 36px;
}
</style>
<template>
  <!-- 底部 -->
  <div class="setting-jing setting-jingnei">
    <div class="message-bottom">
      <div v-loading="loading">
        <div v-if="textData.length != 0">
          <!-- 每一个模块 -->
          <div
            class="bottom-count"
            v-for="item in textData"
            :key="item.accurateId"
          >
            <div class="bottom-count-box">
              <div
                :class="[
                  'type',
                  item.tonalState == 2
                    ? 'red'
                    : item.tonalState == 1
                    ? 'neutral'
                    : 'obverse',
                ]"
              >
                {{
                  item.tonalState == 2
                    ? "负面"
                    : item.tonalState == 0
                    ? "正面"
                    : "中性"
                }}
              </div>
              <div v-if="item.mediaLink != null" class="type website">
                {{ item.mediaLink }}
              </div>
            </div>
            <div class="bottom-count-text">
              <div class="bottom-comment-box">
                <div style="display: flex">
                  <span
                    v-show="item.isEarlyWarn == 1"
                    class="comment-box-yujing"
                    >已预警</span
                  >
                  <p
                    class="bottom-title"
                    v-html="item.title ? item.title : ''"
                    @click="hangel(item)"
                  ></p>
                  <span class="original" @click="lookOriginal(item.website)">
                    <img src="../../../assets/img/views/wenzhang.png" alt />
                    <span>查看原文</span>
                  </span>
                </div>

                <div class="bottom-comment" v-if="activeNames != 'six'">
                  <span
                    :class="[
                      'comment-pinglun',
                      item.commentCount == 0 ? 'grey' : 'blue',
                    ]"
                    >{{ item.commentCount == 0 ? "无评论" : "有评论" }}</span
                  >
                  <span
                    :class="[
                      'comment-xiangsi',
                      !item.likeAmount ? 'grey' : 'blue',
                    ]"
                    >相似({{ item.likeAmount ? item.likeAmount : "0" }})</span
                  >
                </div>
              </div>
              <!-- 评论数量等 -->

              <p
                class="bottom-text"
                v-html="item.abstracts ? item.abstracts : ''"
                @click="hangel(item)"
              ></p>
              <div class="bottom-text-box">
                <div class="bottom-text-caozuo">
                  <span class="bottom-meiti"
                    >作者ID:{{item.accurateId ? item.accurateId : "无"}}</span
                  >
                  <span class="bottom-meiti"
                    >媒体性质:{{
                      (item.mediaLink ? item.mediaLink : "其它") +
                      "/" +
                      (item.mediaAttribute ? item.mediaAttribute : "其它") +
                      "/" +
                      (item.mediaLevel ? item.mediaLevel : "其它")
                    }}</span
                  >
                  <el-tooltip
                    class="item"
                    :content="
                      (item.source ? item.source : '无') +
                      '/' +
                      (item.author ? item.author : '无')
                    "
                    effect="dark"
                    placement="top"
                  >
                    <span class="publics source" style="display: flex">
                      来源/作者:
                      <span v-html="item.source ? item.source : '无'"></span>
                      <span>/</span>
                      <span v-html="item.author ? item.author : '无'"></span>
                    </span>
                  </el-tooltip>
                  <span class="publics bottom-gengduo">
                    事件分类：
                    <span>{{ item.eventList[0] }}</span>
                    <el-tooltip
                      v-if="item.eventClass && item.eventList.length >= 2"
                      class="item"
                      effect="dark"
                      placement="top"
                    >
                      <div slot="content" v-html="item.eventClass"></div>
                      <span class="gengduo-link">(更多)</span>
                    </el-tooltip>
                  </span>
                  <span class="publics bottom-key">
                    关键字:
                    <span v-if="item.keyword">
                      <span
                        v-for="(ite, indx) in item.keywordList"
                        :class="[
                          ite != item.keywordList[item.keywordList.length - 1]
                            ? 'styleMargin'
                            : '',
                        ]"
                        :key="indx"
                        >{{ ite }}</span
                      >
                    </span>
                    <span v-else>无</span>
                    <el-tooltip
                      v-if="item.keyword && item.keywordArry.length > 3"
                      class="item"
                      effect="dark"
                      placement="top"
                    >
                      <div slot="content" v-html="item.keyword"></div>
                      <span class="gengduo-link">(更多)</span>
                    </el-tooltip>
                  </span>
                </div>
                <span class="publics bottom-time">{{
                  item.releaseTime ? item.releaseTime : "无"
                }}</span>
              </div>

              <div class="bottom-operation">
                <!-- 设置标签 -->
                <el-select
                  v-if="type == 1"
                  ref="selectLael"
                  class="select-biaoqian"
                  v-model="item.setLabel"
                  placeholder="设置标签"
                  @change="changeLabel(item)"
                >
                  <el-option
                    v-for="(ite, index) in biaoqianList"
                    :key="index"
                    :label="ite.labelName"
                    :value="ite.labelId"
                  ></el-option>
                </el-select>
                <div class="operation-box">
                  <div class="operation-copy my-caozuo" @click="copy(item)">
                    <span>
                      <span>
                        <img src="../../../assets/img/views/fuzhi.png" alt />
                      </span>
                    </span>
                    <span>复制</span>
                  </div>
                  <div
                    v-if="activeName != 'six'"
                    class="operation-shoucang my-caozuo"
                    @click="editShouc(item)"
                  >
                    <span>
                      <span>
                        <img
                          v-if="item.isCollect == '1'"
                          src="~@/assets/img/views/yellowsc.png"
                          alt
                        />
                        <img
                          v-else
                          src="~@/assets/img/views/shoucang.png"
                          alt
                        />
                      </span>
                    </span>
                    <span
                      :class="[item.isCollect == '1' ? 'yishoucang' : ' ']"
                      >{{ item.isCollect == "1" ? "已收藏" : "收藏" }}</span
                    >
                  </div>
                  <div
                    v-if="type == 1"
                    class="operation-delete my-caozuo"
                    @click="deletes(item)"
                  >
                    <span>
                      <span>
                        <img src="../../../assets/img/views/delete.png" alt />
                      </span>
                    </span>
                    <span>取消标签</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- 每一个模块 -->
        </div>
        <div class="analyse-container" v-else>
          <div class="analyse-container-img">
            <img src="../../../assets/img/views/analyse-img.png" />
            <p>暂无数据</p>
          </div>
        </div>
      </div>

      <div class="block">
        <span class="total">
          共{{ tablePage.total }}条, 当前显示第{{
            tablePage.page * tablePage.pageSize - tablePage.pageSize + 1
          }}-{{
            tablePage.page * tablePage.pageSize > tablePage.total
              ? tablePage.total
              : tablePage.page * tablePage.pageSize
          }}条
        </span>
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="tablePage.page"
          :page-sizes="tablePage.pageSizes"
          :page-size="tablePage.pageSize"
          layout="sizes, prev, pager, next,jumper"
          :total="tablePage.total"
        ></el-pagination>
      </div>
    </div>
    <deleteDialog ref="openDialog" :getList="getListInof" />
  </div>
</template>

<script>
import deleteDialog from "../../../components/deleteDialog";
import {
  accurateList,
  setLabel,
  modifyLabel,
  sentimentEdit,
  deleteLabel,
} from "../../../api/accurate/list";
import { reportList } from "../../../api/setting/list";
export default {
  data() {
    return {
      // 父组件头部切换
      activeName: sessionStorage.getItem("activeName"),
      loading: false,
      // 开始时间
      startTime: "",
      // 结束时间
      // 设置标签
      biaoqian: "",
      // 用来存储旧的标签
      oldLabel: 0,
      biaoqianList: [],
      // 内容
      textData: [],
      //页数
      tablePage: {
        pageSizes: [10, 20, 30, 40, 50],
        page: 1,
        pageSize: 10,
        total: 0,
      },
      serahList: {},
      // 头部切换选中
      activeNames: sessionStorage.getItem("activeName"),
    };
  },
  props: {
    labelId: {
      type: Number,
    },
    type: {
      type: Number,
    },
  },
  components: {
    deleteDialog,
  },
  methods: {
    hangel(item) {
      let routeUrl = this.$router.resolve({
        path: "/systemSettings/index/yuqing",
        query: {
          info: encodeURIComponent(
            JSON.stringify({
              type: "setting",
              dataId: item.dataId,
              id: item.accurateId,
            })
          ),
        },
      });
      window.open(routeUrl.href, "_blank");
    },
    // 复制
    copy(msg) {
      this.$publicFun.copyFun(msg.copy);
    },
    // 取消标签
    async deletes(item) {
      const res = await deleteLabel({
        accurateId: item.accurateId,
      });
      if (res.data.code == 200) {
        this.$message({
          message: "恭喜你，操作成功！",
          type: "success",
        });
      } else {
        this.$message.error("操作失败，请稍后再试!");
      }
      setTimeout(() => {
        // 刷新页面
        if (this.textData.length == 1) {
          if (this.tablePage.page > 1) {
            this.tablePage.page = this.tablePage.page - 1;
          }
        }
        this.getListInof();
      }, 1000);
    },
    // 收藏
    async editShouc(item) {
      let res = await sentimentEdit({
        accurateId: item.accurateId,
        organId: item.organId,
        isCollect: item.isCollect === "0" ? "1" : "0",
      });
      if (res != undefined) {
        this.$message({
          message: res.data.msg,
          type: "success",
        });
        item.isCollect = item.isCollect === "0" ? "1" : "0";
      }
      if (this.type == 2) {
        setTimeout(() => {
          if (this.textData.length == 1) {
            if (this.tablePage.page > 1) {
              this.tablePage.page = this.tablePage.page - 1;
            }
          }
          this.getListInof();
        }, 2000);
      }
    },
    // 刷新页面
    // 获取列表
    resetNum() {
      this.serahList = {};
      this.tablePage.page = 1;
      this.getListInof();
    },
    //查看原文
    lookOriginal(web) {
      window.open(web);
    },
    async getListInof(obj, type) {
      obj ? (this.serahList = obj) : false;
      let res;
      this.loading = true;
      let data = {
        organId: this.$store.state.user.organizationId,
        pageSize: this.tablePage.pageSize,
        pageNum: this.tablePage.page,
      };
      if (type == "search") {
        this.tablePage.page = 1;
      }
      if (Object.values(this.serahList).length > 0) {
        // 有值
        this.serahList.organId = this.$store.state.user.organizationId;
        this.serahList.pageSize = this.tablePage.pageSize;
        this.serahList.pageNum = 1;
        data = this.serahList;
        data.pageNum = this.tablePage.page;
        data.pageSize = this.tablePage.pageSize;
      }
      // 标签页面跳转
      if (this.type == 1) {
        data.setLabel = this.labelId;
        res = await accurateList(data);
        if (res.data.data) {
          if (res.data.data.result) {
            this.textData = res.data.data.result;
            this.tablePage.total = res.data.data.total;
          }
        }
      }
      // 收藏页面跳转
      if (this.type == 2) {
        data.isCollect = 1;
        res = await accurateList(data);
        if (res.data.data) {
          if (res.data.data.result) {
            this.textData = res.data.data.result;
            this.tablePage.total = res.data.data.total;
          }
        }
      }
      // 报告页面
      if (this.type == 3) {
        let url = `?pageSize=${this.tablePage.pageSize}&pageNum=${this.tablePage.page}`;
        res = await reportList(data, url);
        if (res.data.data) {
          if (res.data.data.rows) {
            this.textData = res.data.data.rows;
            this.tablePage.total = res.data.data.total;
          }
        }
      }
      this.textData.forEach((item) => {
        item.accurateId = item.accurateId + "";
        item.dataId = item.dataId + "";
        item.releaseTime = item.releaseTime.slice(0, 19).replace("T", " ");
        if (item.eventClass) {
          item.eventList = item.eventClass.split(" ");
          let reg = new RegExp(" ", "g"); //g代表全部
          item.eventClass = item.eventClass.replace(reg, "<br/>");
        } else {
          item.eventList = [];
        }
        if (item.keyword) {
          item.keywordList = item.keyword.split(" ");
          item.keywordArry = item.keyword.split(" ");
          if (item.keywordList.length > 3) {
            item.keywordList = item.keywordList.slice(0, 3);
          }
          let reg = new RegExp(" ", "g"); //g代表全部
          item.keyword = item.keyword.replace(reg, "<br/>");
        }
      });
      this.loading = false;
    },
    // 获取标签
    async setLabel() {
      const res = await setLabel({
        organizationId: this.$store.state.user.organizationId,
      });
      this.biaoqianList = res.data.rows;
    },
    async changeLabel(val) {
      let res = await modifyLabel({
        dataId: val.dataId,
        setLabel: val.setLabel,
        organId: this.$store.state.user.organizationId,
      });
      if (res.data.code == 200) {
        this.$message({
          message: "修改标签成功！",
          type: "success",
        });
        setTimeout(() => {
          this.getListInof();
        }, 1000);
      } else {
        this.$message.error("修改标签失败！");
      }
    },
    // 分页
    handleSizeChange(val) {
      this.tablePage.pageSize = val;
      this.tablePage.page = 1;
      this.getListInof();
      window.scrollTo(0, 0);
    },
    handleCurrentChange(val) {
      this.tablePage.page = val;
      this.getListInof();
      window.scrollTo(0, 0);
    },
    // 序号
    indexMethod(index) {
      return (
        index +
        this.tablePage.page * this.tablePage.pageSize -
        this.tablePage.pageSize +
        1
      );
    },
  },
  created() {
    this.getListInof();
    // 获取标签
    this.setLabel();
  },
};
</script>

<style scope>
.setting-jingnei .message-bottom {
  padding-bottom: 20px;
  margin-top: 16px;
  width: 100%;
  border-radius: 4px;
  background-color: #fff;
}
.setting-jingnei .bottom-count {
  display: flex;
  padding: 36px 40px;
  border-bottom: 1px solid #e4e6ec;
}
.bottom-count:hover {
  box-shadow: 0px 2px 4px 4px rgb(0 0 0 / 6%);
}
.setting-jingnei .bottom-count-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.setting-jingnei .type {
  min-width: 76px;
  font-size: 14px;
  text-align: center;
  color: #ffffff;
  height: 28px;
  line-height: 28px;
  border-radius: 1px;
}
.setting-jingnei .type:nth-child(2) {
  top: 115px;
}
.setting-jingnei .website {
  margin-top: 24px;
  height: 28px;
  background: #868bff;
  border-radius: 1px;
}
.setting-jingnei .bottom-count-text span {
  display: inline-block;
}
.setting-jingnei .bottom-count-text {
  display: inline-block;
  padding-left: 40px;
  width: calc(100% - 40px);
}
.setting-jingnei .bottom-comment-box {
  display: flex;
  padding-right: 17px;
  justify-content: space-between;
}
.analyse-container {
  width: 100%;
  height: 300px;
  background: #ffffff;
  border-radius: 4px;
  margin-top: 16px;
  text-align: center;
}

.analyse-container-img {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.analyse-container-img p {
  font-size: 14px;
  color: #999999;
}
.setting-jingnei .comment-box-yujing {
  width: 60px;
  margin-left: 0;
  margin-right: 16px;
  padding: 2px 8px;
  font-size: 14px;
  font-weight: bold;
  background: #ffedef;
  color: #ea3342;
}
.setting-jingnei .bottom-title {
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 1008px;
  font-size: 18px;
  font-weight: bold;
  color: #333333;
}
.setting-jingnei .bottom-comment {
  font-size: 14px;
}
.setting-jingnei .bottom-comment-box {
  display: flex;
  padding-right: 17px;
  justify-content: space-between;
}
.setting-jingnei .yidu {
  color: #02bc7c;
}
.setting-jingnei .bottom-text {
  cursor: pointer;
  line-height: 20px;
  overflow: hidden;
  height: 40px;
  font-size: 14px;
  color: #666666;
  margin-top: 16px;
}
.setting-jingnei .bottom-text-box {
  margin-top: 16px;
  padding-right: 17px;
  display: flex;
  justify-content: space-between;
}
.setting-jingnei .bottom-text-caozuo {
  display: flex;
  align-items: center;
}
.setting-jingnei .bottom-text-caozuo > span:not(:first-child) {
  margin-left: 40px;
}
.styleMargin {
  margin-right: 5px;
}
.setting-jingnei .bottom-meiti {
  font-size: 14px;
  color: #b8bcc3;
}
.setting-jingnei .publics {
  font-size: 14px;
  color: #b8bcc3;
}
.setting-jingnei .gengduo-link {
  cursor: pointer;
  color: #2e59ec;
  margin-left: 5px;
}
.setting-jingnei .bottom-operation {
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
}
.setting-jingnei .bottom-operation img {
  width: 16px;
  height: 16px;
}
.setting-jingnei .operation-box {
  margin-right: 16px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  font-size: 14px;
  color: #333333;
  line-height: 20px;
}
.setting-jingnei .operation-box > div span:nth-child(1):nth-child(1) {
  position: relative;
  width: 16px;
  height: 16px;
  margin-right: 8px;
}
.setting-jingnei .operation-box > div span:nth-child(1) span {
  position: absolute;
  top: 3px;
  left: 0;
}
.setting-jingnei .yishoucang {
  color: #ff9000;
}
.setting-jingnei .yiyujing {
  color: #ea3342;
}
.setting-jingnei .comment-pinglun {
  margin-right: 16px;
}
.my-caozuo {
  margin-left: 40px;
}
.original {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 14px;
  color: #2e59ec;
  margin-left: 16px;
}
.original img {
  position: relative;
  top: 4px;
}
.source {
  max-width: 370px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
<template>
  <!-- eslint-disable -->
  <div class="essential">
    <div class="content_top">
      <img class="content_top_img" src="../../../assets/img/views/topqq.png" />
      <span class="content_top_font">预警模型接收设置</span>
    </div>
    <!-- 表格 -->
    <div class="essential_content">
      <div class="essential_table">
        <el-table
          :data="tableData"
          stripe
          :header-cell-style="headerStyle"
          :cell-style="{
            textAlign: 'center',
          }"
          style="width: 100%"
        >
          <el-table-column prop="name" label="姓名"></el-table-column>
          <el-table-column prop="name2" label="预警接收时间">
            <template slot-scope="scope">
              <span>
                {{ scope.row.warnReceptionStime }} --
                {{ scope.row.warnReceptionEtime }}
              </span>
            </template>
          </el-table-column>
          <el-table-column label="预警调性">
            <template slot-scope="scope">
              <span>{{scope.row.warnType}}</span>
            </template>
          </el-table-column>
          <el-table-column label="预警事件">
            <template slot-scope="scope">
              <el-button
                class="bianji"
                type="text"
                style="color: #02bc7c; margin-right: 10px"
                @click="handleLook(scope.row)"
              >查看</el-button>
            </template>
          </el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-button
                class="bianji"
                type="text"
                style="margin-right: 10px"
                @click="handleEdit(scope.row)"
              >编辑</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <!-- 查看弹窗 -->
    <el-dialog title="查看" :visible.sync="warning_dialog_look" width="700px">
      <div style="padding: 0px 20px">
        <div class="warning_dialog_margin">
          <span class="essential_content_tag">姓名:</span>
          <span class="essential_content_text">{{ dialog_look.name }}</span>
        </div>
        <div class="warning_moudle_time warning_dialog_margin">
          <span class="essential_content_tag">预警接收时间:</span>
          <span class="essential_content_text">
            {{ dialog_look.warnReceptionStime }} 至
            {{ dialog_look.warnReceptionEtime }}
          </span>
        </div>
        <div class="warning_dialog_margin">
          <span class="essential_content_tag">预警调性:</span>
          <span class="essential_content_text">{{dialog_look.warnType}}</span>
        </div>
        <div class="Warning_events">
          <span class="essential_content_tag warning_moudle_tag">预警事件:</span>
          <div class="tree_wrap" style="margin-top: 16px">
            <div class="tree_top">
              <span
                class="essential_content_tag"
              >部分事件({{ functionDataRuleForm.CheckedNodes.length }})</span>
            </div>
            <div class="tree_content">
              <el-tree
                v-if="warning_dialog_look"
                :load="loadNode2"
                lazy
                node-key="eventId"
                ref="tree"
                :props="defaultProps"
                :default-expand-all="true"
              ></el-tree>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
    <!-- 编辑弹窗 -->
    <el-dialog title="编辑" :visible.sync="warning_dialog_change" width="700px">
      <div style="padding: 0px 20px">
        <span class="essential_content_tag">姓名:</span>
        <div class="warning_dialog_space">
          <el-input style="width: 280px" v-model="ruleForm.name" size="medium" disabled></el-input>
        </div>
        <span class="essential_content_tag">预警接收时间:</span>
        <div class="warning_dialog_space">
          <el-time-picker
            style="width: 280px"
            is-range
            v-model="ruleForm.time"
            range-separator="一"
            format="HH:mm"
            value-format="HH:mm"
            start-placeholder="开始时间"
            end-placeholder="结束时间"
            placeholder="选择时间范围"
          ></el-time-picker>
        </div>
        <span class="essential_content_tag">预警调性:</span>
        <div class="warning_dialog_space">
          <el-checkbox-group style="display: inline-block" v-model="ruleForm.checkList">
            <el-checkbox
              v-for="(item,index) in ruleForm.changList"
              :key="index"
              :label="item"
              style="margin-right: 40px"
            ></el-checkbox>
            <!-- <el-checkbox label="正面" style="margin-right: 80px"></el-checkbox>
            <el-checkbox label="负面" style="margin-right: 80px"></el-checkbox>
            <el-checkbox label="中性"></el-checkbox>-->
          </el-checkbox-group>
        </div>
        <div class="Warning_events">
          <span class="essential_content_tag warning_moudle_tag">预警事件:</span>
          <div class="tree_wrap" style="margin-top: 16px">
            <div class="tree_top">
              <el-checkbox v-model="checked" @change="setCheckedKeys">全部事件</el-checkbox>
            </div>
            <el-tree
              v-if="warning_dialog_change"
              :load="loadNode"
              @check-change="treeChange"
              :default-checked-keys="ruleForm.CheckedNodes"
              lazy
              show-checkbox
              node-key="eventId"
              ref="tree"
              :props="defaultProps"
              :default-expand-all="true"
            ></el-tree>
          </div>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button class="cancal_z" @click="warning_dialog_change = false">取 消</el-button>
        <el-button class="search_z" type="primary" @click="changeYes">保存</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      headerStyle: {
        background: "#F6F7FA",
        fontSize: "16px",
        color: "#333",
        textAlign: "center"
      },
      // 预警模型接收设置
      tableData: [],
      //prps
      defaultProps: {
        label: "eventName",
        id: "eventId"
      },
      // 查看下的预警事件
      functionDataRuleForm: {
        HalfCheckedNodes: [], // 事件id
        CheckedNodes: []
      },

      // 编辑下的预警事件
      ruleForm: {
        name: "",
        time: [],
        checkList: [],
        changList: [],
        HalfCheckedNodes: [], // 事件id
        CheckedNodes: [],
        id: "",
        organizationId: "",
        customerId: ""
      },
      // 弹窗
      warning_dialog_look: false,
      warning_dialog_change: false,
      dialog_look: {},
      ids: [],
      checked: false,
      activeName: "first"
    };
  },
  // 父组件传过来的预警调性
  props: ["warningCom"],
  created() {
    this.getTableData();
  },
  methods: {
    // 获取列表
    getTableData() {
      this.$http({
        method: "post",
        url: "/api/organization/warnmodel/list",
        data: {
          organizationId: this.$store.state.user.organizationId,
          // customerId: '934ca867-9b82-4da7-b08e-ca6d77739ac1',
          customerId: this.$store.state.user.customerId
        }
      })
        .then(res => {
          if (res.data.code == 200) {
            if (res.data.data) {
              if (res.data.data[0].warnType) {
                if (res.data.data[0].warnType.slice(0, 1) == ",") {
                  res.data.data[0].warnType = res.data.data[0].warnType.slice(
                    1
                  );
                }
              }
            }

            this.tableData = res.data.data;
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    // 查看
    handleLook(row) {
      this.warning_dialog_look = true;
      this.dialog_look = row;
      this.functionDataRuleForm.HalfCheckedNodes = JSON.parse(
        row.eventId
      ).HalfCheckedNodes;
      this.functionDataRuleForm.CheckedNodes = JSON.parse(
        row.eventId
      ).CheckedNodes;
    },
    // 查看下的懒加载树状图
    loadNode2(node, resolve) {
      this.loading = true;
      let dataAll = [
        this.functionDataRuleForm.HalfCheckedNodes,
        this.functionDataRuleForm.CheckedNodes
      ].flat(Infinity);
      if (node.level === 0) {
        this.$http({
          method: "post",
          url: "/api/system/event/list",
          data: {
            parentId: 1
          }
        })
          .then(res => {
            if (res.data.code == 200) {
              let data = [];
              dataAll.forEach(x => {
                res.data.data.forEach(n => {
                  if (x == n.eventId) {
                    data.push(n);
                  }
                });
              });
              resolve(data);
            }
          })
          .catch(error => {
            console.log(error);
          });
      }
      if (node.level >= 1 && node.level <= 2) {
        dataAll.forEach(x => {
          if (x == node.data.eventId) {
            this.$http({
              method: "post",
              url: "/api/system/event/list",
              data: {
                parentId: node.data.eventId
              }
            })
              .then(res => {
                if (res.data.code == 200) {
                  let data = [];
                  dataAll.forEach(x => {
                    res.data.data.forEach(n => {
                      if (x == n.eventId) {
                        data.push(n);
                      }
                    });
                  });
                  resolve(data);
                }
              })
              .catch(error => {
                console.log(error);
              });
          }
        });
      } else {
        resolve([]);
      }
      this.loading = false;
    },
    // 编辑按钮
    handleEdit(row) {
      this.warning_dialog_change = true;
      this.checked = false;
      this.functionDataRuleForm.HalfCheckedNodes = JSON.parse(
        row.eventId
      ).HalfCheckedNodes;
      this.functionDataRuleForm.CheckedNodes = JSON.parse(
        row.eventId
      ).CheckedNodes;

      this.ruleForm.name = row.name;
      this.ruleForm.time = [row.warnReceptionStime, row.warnReceptionEtime];
      this.ruleForm.checkList = row.warnType.split(",");
      this.ruleForm.changList = this.warningCom.split(",");
      this.ruleForm.id = row.id;
      this.ruleForm.organizationId = row.organizationId;
      this.ruleForm.customerId = row.customerId;
    },
    //编辑的懒加载树状图
    loadNode(node, resolve) {
      if (node.level === 0) {
        this.ids = [];
        this.$http({
          method: "post",
          url: "/api/system/event/list",
          data: {
            parentId: 1
          }
        })
          .then(res => {
            if (res.data.code == 200) {
              res.data.data.forEach(x => {
                this.ids.push(x.eventId);
              });
              if (
                this.ids.length == this.functionDataRuleForm.CheckedNodes.length
              ) {
                this.checked = true;
              }
              resolve(res.data.data);
            }
          })
          .catch(error => {
            console.log(error);
          });
      }
      if (node.level >= 1) {
        this.$http({
          method: "post",
          url: "/api/system/event/list",
          data: {
            parentId: node.data.eventId
          }
        })
          .then(res => {
            if (res.data.code == 200) {
              resolve(res.data.data);
              this.$refs.tree.setCheckedKeys(
                this.functionDataRuleForm.CheckedNodes
              );
            }
          })
          .catch(error => {
            console.log(error);
          });
      }
    },
    //编辑下选择
    treeChange() {
      let HalfCheckedNodes = [];
      let CheckedNodes = [];
      this.$refs.tree.getCheckedNodes().forEach(x => {
        CheckedNodes.push(x.eventId);
      });
      this.$refs.tree.getHalfCheckedNodes().forEach(x => {
        HalfCheckedNodes.push(x.eventId);
      });
      this.ruleForm.HalfCheckedNodes = HalfCheckedNodes;
      this.ruleForm.CheckedNodes = CheckedNodes;
    },
    // 树节点的选择
    setCheckedKeys() {
      if (this.checked == true) {
        this.$refs.tree.setCheckedKeys(this.ids);
      } else {
        this.$refs.tree.setCheckedKeys([]);
      }
    },
    // 提交表单
    changeYes() {
      let data = {
        warnReceptionStime: this.ruleForm.time[0],
        warnReceptionEtime: this.ruleForm.time[1],
        warnType: this.ruleForm.checkList.join(","),
        id: this.ruleForm.id,
        customerId: this.ruleForm.customerId,
        organizationId: this.ruleForm.organizationId,
        eventId: JSON.stringify({
          HalfCheckedNodes: this.ruleForm.HalfCheckedNodes,
          CheckedNodes: this.ruleForm.CheckedNodes
        })
      };
      this.$http({
        method: "post",
        url: "/api/organization/warnmodel/edit",
        data: data
      })
        .then(res => {
          if (res.data.code == 200) {
            this.$message({
              message: "编辑成功",
              type: "success"
            });
            this.getTableData();
            this.warning_dialog_change = false;
          }
        })
        .catch(err => {
          console.log(err);
        });
    }
  }
};
</script>

<style scoped>
.top_img,
.top_font,
.top_switch {
  display: inline-block;
  vertical-align: middle;
}

.top_img {
  cursor: pointer;
}

.top_font {
  font-size: 20px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: bold;
  color: #333333;
  margin: 0 24px 0 16px;
}

.essential {
  background: #ffffff;
  border-radius: 4px;
  padding: 24px;
  margin-top: 16px;
}

.content_top {
  overflow: hidden;
}

.content_top_font,
.content_top_icon,
.content_top_img {
  display: inline-block;
  vertical-align: bottom;
}

.content_top_img {
  position: relative;
  top: 2px;
}

.content_top_font {
  font-size: 16px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: bold;
  color: #333333;
  margin-left: 5px;
}

.bianji {
  font-size: 14px;
  font-family: PingFangSC-Medium, PingFang SC;
  color: #2e59ec;
  margin-left: 10px;
  padding: 0;
}

.essential_content {
  padding: 20px;
  margin-top: 28px;
}

.essential_content_tag {
  font-size: 14px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: bold;
  color: #333333;
  margin-right: 16px;
}

.essential_content_text {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  color: #666666;
  margin-right: 40px;
}

.essential_table {
  margin-top: 24px;
}

.bianji,
.table_line,
.shanchu {
  display: inline-block;
  vertical-align: middle;
}

.tree_wrap {
  display: inline-block;
  vertical-align: top;
  width: 600px;
  min-height: 330px;
  border: 1px solid #d8d8d8;
  margin-right: 62px;
}

.warning_moudle_tag {
  display: inline-block;
  vertical-align: top;
}

.tree_top {
  width: 100%;
  padding: 12px 24px;
  border-bottom: solid 1px #d8d8d8;
}

.tree_content {
  padding: 24px;
  overflow-y: auto;
}

.dialog_margin {
  margin-top: 10px;
}

.warning_dialog_margin {
  margin-bottom: 20px;
}

.warning_dialog_space {
  margin: 20px 0;
}
</style>

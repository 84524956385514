<template>
  <!-- 底部 -->
  <div class="setting-jing jingwai">
    <div class="message-bottom">
      <!-- 每一个模块 -->
      <div v-loading="loading">
        <div v-if="textData.length != 0">
          <div class="bottom-count" v-for="item in textData" :key="item.dataId">
            <div class="bottom-count-box">
              <div
                :class="['type', item.sensitives==0?'english':'red']"
              >{{ item.sensitives==0?'非敏感':'敏感' }}</div>
              <div class="type website">{{ item.mediaType ? item.mediaType : "无" }}</div>
            </div>
            <div class="bottom-count-text">
              <div class="bottom-comment-box">
                <div style="display: flex">
                  <span v-show="item.isEarlyWarn == '1'" class="comment-box-yujing">已预警</span>
                  <p
                    v-if="item.titleTraslation"
                    v-html="item.titleTraslation"
                    class="bottom-title"
                    @click="hangel(item.dataId)"
                  ></p>
                  <p v-html="item.title" class="bottom-title" @click="hangel(item.dataId)" v-else></p>
                  <span class="original" @click="lookOriginal(item.website)">
                    <img src="../../../assets/img/views/wenzhang.png" alt />
                    <span>查看原文</span>
                  </span>
                </div>

                <div class="bottom-comment" v-if="activeNames!='six'">
                  <span :class="['comment-xiangsi',item.likeAmount ? 'blue' : 'grey',]">
                    <!-- 相似({{item.likeAmount?item.likeAmount:'0'}}) -->
                    {{ item.likeAmount ? "有相似" : "无相似" }}
                  </span>
                </div>
              </div>
              <!-- 评论数量等 -->
              <div
                v-if="item.contentTraslation"
                v-html="item.contentTraslation"
                class="bottom-text"
                @click="hangel(item.dataId)"
              ></div>
              <div v-else v-html="item.content" class="bottom-text" @click="hangel(item.dataId)"></div>
              <div class="bottom-text-box">
                <div class="bottom-text-caozuo">
                  <span class="publics source">
                    媒体类型:
                    {{ item.mediaType ? item.mediaType : "无" }}
                  </span>
                  <span class="publics source">
                    媒体归属:
                    {{ item.mediaOwnership ? item.mediaOwnership : "无" }}
                  </span>
                  <span class="publics source">
                    媒体阵营:
                    {{ item.mediaCamp ? item.mediaCamp : "无" }}
                  </span>
                  <span class="publics source">
                    语言类型:
                    {{ item.languageType ? item.languageType : "无" }}
                  </span>
                  <span class="publics bottom-gengduo">
                    来源/作者:
                    <span v-html="item.source ? item.source : '无'"></span>
                    <span>/</span>
                    <span v-html="item.sourceAuthor ? item.sourceAuthor : '无'"></span>
                  </span>
                  <span v-if="item.keyword" class="publics bottom-key">
                    关键字：
                    <span
                      v-for="(ite, indx) in item.keywordList"
                      :class="[
                        ite != item.keywordList[item.keywordList.length - 1]
                          ? 'styleMargin'
                          : '',
                      ]"
                      :key="indx"
                    >{{ ite }}</span>
                    <el-tooltip
                      v-if="item.keyword && item.keywordArry.length > 3"
                      class="item"
                      effect="dark"
                      placement="top"
                    >
                      <div slot="content" v-html="item.keyword"></div>
                      <span class="gengduo-link">(更多)</span>
                    </el-tooltip>
                  </span>
                  <span v-else class="publics bottom-key">
                    关键字：
                    <span>无</span>
                  </span>
                </div>
                <span class="publics bottom-time">
                  {{
                  item.releaseTime ? item.releaseTime : "无"
                  }}
                </span>
              </div>

              <div class="bottom-operation">
                <!-- 设置标签 -->
                <el-select
                  v-if="type == 1"
                  size="small"
                  class="select-biaoqian"
                  v-model="item.setLabel"
                  placeholder="设置标签"
                  @change="changeLabel(item)"
                >
                  <el-option
                    v-for="(item, index) in biaoqianList"
                    :key="index"
                    :label="item.labelName"
                    :value="item.labelId"
                  ></el-option>
                </el-select>
                <div class="operation-box">
                  <div class="operation-copy my-caozuo" @click="copy(item.copy)">
                    <span>
                      <span>
                        <img src="../../../assets/img/views/fuzhi.png" alt />
                      </span>
                    </span>
                    <span>复制</span>
                  </div>
                  <div
                    v-if="activeName!='six'"
                    class="operation-shoucang my-caozuo"
                    @click="collect(item)"
                  >
                    <span>
                      <span>
                        <img
                          v-if="item.isCollect == '1'"
                          src="../../../assets/img/views/yellowsc.png"
                          alt
                        />
                        <img v-else src="../../../assets/img/views/shoucang.png" alt />
                      </span>
                    </span>
                    <span
                      :class="[item.isCollect == '1' ? 'yishoucang' : ' ']"
                    >{{ item.isCollect == "1" ? "已收藏" : "收藏" }}</span>
                  </div>
                  <div class="operation-delete my-caozuo" @click="deletes(item)" v-if="type == 1">
                    <span>
                      <span>
                        <img src="../../../assets/img/views/delete.png" alt />
                      </span>
                    </span>
                    <span>取消标签</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="analyse-container" v-else>
          <div class="analyse-container-img">
            <img src="../../../assets/img/views/analyse-img.png" />
            <p>暂无数据</p>
          </div>
        </div>
      </div>

      <!-- 每一个模块 -->
      <div class="block">
        <span class="total">
          共{{ tablePage.total }}条, 当前显示第{{
          tablePage.page * tablePage.pageSize - tablePage.pageSize + 1
          }}-{{
          tablePage.page * tablePage.pageSize > tablePage.total
          ? tablePage.total
          : tablePage.page * tablePage.pageSize
          }}条
        </span>
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="tablePage.page"
          :page-sizes="tablePage.pageSizes"
          :page-size="tablePage.pageSize"
          layout="sizes, prev, pager, next,jumper"
          :total="tablePage.total"
        ></el-pagination>
      </div>
    </div>
    <deleteDialog ref="openDialog" :getList="getListInof" />
  </div>
</template>

<script>
import deleteDialog from "../../../components/deleteDialog";
import { abroadList, editLabel, deleteLabel } from "../../../api/abroad/list";
import { setLabel } from "../../../api/accurate/list";
import { isReportList } from "../../../api/setting/list";

export default {
  data() {
    return {
      // 父组件头部切换
      activeName: sessionStorage.getItem("activeName"),
      loading: false,
      // 表单

      formInline: {
        user: "",
        region: "",
        input1: "",
        value1: ""
      },
      textData: [],
      // 设置标签
      biaoqianList: [],
      //页数
      tablePage: {
        pageSizes: [10, 20, 30, 40, 50],
        page: 1,
        pageSize: 10,
        total: 0
      },
      serahList: {},
      // 头部切换选中
      activeNames: sessionStorage.getItem("activeName")
    };
  },
  props: {
    labelId: {
      type: Number
    },
    type: {
      type: Number
    }
  },
  components: {
    deleteDialog
  },
  methods: {
    hangel(id) {
      let routeUrl = this.$router.resolve({
        path: "/systemSettings/index/xiangqing",
        query: {
          info: encodeURIComponent(
            JSON.stringify({
              id: id,
              activeName: "first",
              type: "setting"
            })
          )
        }
      });
      window.open(routeUrl.href, "_blank");
    },
    copy(msg) {
      this.$publicFun.copyFun(msg);
    },
    // 取消标签
    async deletes(item) {
      const res = await deleteLabel({
        dataId: item.dataId,
        organId: item.organId
      });
      if (res.data.code == 200) {
        this.$message({
          message: "恭喜你，操作成功！",
          type: "success"
        });
      } else {
        this.$message.error("操作失败，请稍后再试!");
      }
      setTimeout(() => {
        if (this.textData.length == 1) {
          if (this.tablePage.page > 1) {
            this.tablePage.page = this.tablePage.page - 1;
          }
        }
        this.getListInof();
      }, 1000);
    },
    // 重置
    resetNum() {
      this.serahList = {};
      this.tablePage.page = 1;
      this.getListInof();
    },
    //查看原文
    lookOriginal(web) {
      window.open(web);
    },
    // 刷新页面
    async getListInof(obj, type) {
      obj ? (this.serahList = obj) : false;
      let res;
      this.loading = true;
      let data = {
        organId: this.$store.state.user.organizationId
      };
      if (type == "search") {
        this.tablePage.page = 1;
      }
      if (Object.values(this.serahList).length > 0) {
        this.serahList.organId = this.$store.state.user.organizationId;
        data.pageSize = this.tablePage.pageSize;
        data.pageNum = 1;
        if (this.serahList.author) {
          this.serahList.sourceAuthor = this.serahList.author;
          delete this.serahList.author;
        }
        if (this.serahList.abstractTraslation) {
          this.serahList.content = this.serahList.abstractTraslation;
          delete this.serahList.abstractTraslation;
        }
        data = this.serahList;
      } else {
        data.pageSize = this.tablePage.pageSize;
        data.pageNum = this.tablePage.page;
      }
      if (this.type == 1) {
        data.setLabel = this.labelId;
        data.pageSize = this.tablePage.pageSize;
        data.pageNum = this.tablePage.page;
        res = await abroadList(data);
        if (res.data.data.result) {
          this.textData = res.data.data.result;
          this.tablePage.total = res.data.data.total;
        }
      }
      if (this.type == 2) {
        data.pageSize = this.tablePage.pageSize;
        data.pageNum = this.tablePage.page;
        data.isCollect = 1;
        res = await abroadList(data);
        if (res.data.data.result) {
          this.textData = res.data.data.result;
          this.tablePage.total = res.data.data.total;
        }
      }
      if (this.type == 3) {
        let url = `?pageNum=${this.tablePage.page}&pageSize=${this.tablePage.pageSize}`;
        res = await isReportList(data, url);
        if (res.data.rows) {
          this.textData = res.data.rows;
          this.tablePage.total = res.data.total;
        }
      }
      if (this.textData) {
        this.textData.forEach(item => {
          if (item.keyword) {
            item.keywordList = item.keyword.split(" ");
            item.keywordArry = item.keyword.split(" ");
            if (item.keywordList.length > 3) {
              item.keywordList = item.keywordList.slice(0, 3);
            }
            let reg = new RegExp(" ", "g"); //g代表全部
            item.keyword = item.keyword.replace(reg, "<br/>");
          }
        });
        this.heightText();
      }
      this.loading = false;
    },
    heightText() {
      this.textData.forEach(item => {
        if (item.keyword) {
          let word = item.keyword.split("<br/>");
          if (word[word.length - 1] == "") {
            word.pop();
          }
          word.forEach(ite => {
            let reg = new RegExp(ite, "g"); //g代表全部
            if (item.title) {
              item.title = item.title.replace(
                reg,
                '<em><span style="color:red">' + ite + "</span></em>"
              );
            }
            if (item.titleTraslation) {
              item.titleTraslation = item.titleTraslation.replace(
                reg,
                '<em><span style="color:red">' + ite + "</span></em>"
              );
            }
            if (item.title != null) {
              item.title = item.title.replace(
                reg,
                '<em><span style="color:red">' + ite + "</span></em>"
              );
            }
            if (item.content != null) {
              item.content = item.content.replace(
                reg,
                '<em><span style="color:red;">' + ite + "</span></em>"
                // font-weight:800;font-size:18px
              );
            }
            if (item.contentTraslation != null) {
              item.contentTraslation = item.contentTraslation.replace(
                reg,
                '<em><span style="color:red">' + ite + "</span></em>"
              );
            }
          });
        }
      });
    },
    async setLabel() {
      const res = await setLabel({
        organizationId: this.$store.state.user.organizationId
      });
      this.biaoqianList = res.data.rows;
    },
    // 修改标签
    async changeLabel(val) {
      let res = await editLabel({
        dataId: val.dataId,
        organId: this.$store.state.user.organizationId,
        setLabel: val.setLabel
      });
      if (res.data.code == 200) {
        this.$message({
          message: "修改标签成功！",
          type: "success"
        });
      } else {
        this.$message.error("修改标签失败！");
      }
    },
    // 收藏
    async collect(item) {
      let res = await editLabel({
        dataId: item.dataId,
        organId: item.organId,
        isCollect: item.isCollect == 0 ? 1 : 0
      });
      if (res != undefined) {
        this.$message({
          message: res.data.msg,
          type: "success"
        });
        item.isCollect = item.isCollect == 0 ? 1 : 0;
      }
      if (this.type == 2) {
        setTimeout(() => {
          if (this.textData.length == 1) {
            if (this.tablePage.page > 1) {
              this.tablePage.page = this.tablePage.page - 1;
            }
          }
          this.getListInof();
        }, 1000);
      }
    },
    // 分页
    handleSizeChange(val) {
      this.tablePage.pageSize = val;
      this.tablePage.page = 1;
      this.getListInof();
      window.scrollTo(0, 0);
    },
    handleCurrentChange(val) {
      this.tablePage.page = val;
      this.getListInof();
      window.scrollTo(0, 0);
    },
    // 序号
    indexMethod(index) {
      return (
        index +
        this.tablePage.page * this.tablePage.pageSize -
        this.tablePage.pageSize +
        1
      );
    }
  },
  created() {
    this.getListInof();
    this.setLabel();
  }
};
</script>

<style scope>
.jingwai .message-bottom {
  padding-bottom: 20px;
  margin-top: 16px;
  width: 100%;
  border-radius: 4px;
  background-color: #fff;
}

.jingwai .bottom-count {
  display: flex;
  padding: 36px 40px;
  border-bottom: 1px solid #e4e6ec;
}
.jingwai .bottom-count-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.jingwai .bottom-line {
  margin-top: 24px;
  width: 100%;
  height: 1px;
  background: #e4e6ec;
}
.jingwai .bottom-title {
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 1008px;
  font-size: 18px;
  font-weight: bold;
  color: #333333;
}
.jingwai .bottom-count-text span {
  display: inline-block;
}
.jingwai .bottom-count-text {
  display: inline-block;
  padding-left: 40px;
  width: calc(100% - 40px);
}
.english {
  height: 28px;
  background-color: #3e82ff;
}
.jingwai .type {
  font-size: 14px;
  text-align: center;
  color: #ffffff;
  min-width: 76px;
  height: 28px;
  line-height: 28px;
  border-radius: 1px;
}
.jingwai .type:nth-child(2) {
  top: 115px;
}
.jingwai .website {
  margin-top: 24px;
  height: 28px;
  background: #868bff;
  border-radius: 1px;
}
.jingwai .publics {
  font-size: 14px;
  color: #b8bcc3;
  margin-top: 16px;
}
.analyse-container {
  width: 100%;
  height: 300px;
  background: #ffffff;
  border-radius: 4px;
  margin-top: 16px;
  text-align: center;
}

.analyse-container-img {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.analyse-container-img p {
  font-size: 14px;
  color: #999999;
}
.jingwai .bottom-text {
  cursor: pointer;
  line-height: 20px;
  overflow: hidden;
  height: 40px;
  font-size: 14px;
  color: #666666;
  margin-top: 16px;
}
.jingwai .bottom-meiti {
  font-size: 14px;
  color: #b8bcc3;
}
.styleMargin {
  margin-right: 5px;
}
.jingwai .message-yuqing .el-checkbox,
.jingwai .message-region .el-checkbox,
.jingwai .message-link .el-checkbox {
  margin-right: 48px;
}
.jingwai .yujing {
  margin-left: 32px;
}
.jingwai .bottom-comment {
  font-size: 14px;
}
.jingwai .comment-pinglun {
  color: #2e59ec;
  margin-right: 16px;
}
.jingwai .bottom-operation {
  justify-content: space-between;
  display: flex;
  margin-top: 16px;
}
.gengduo-link {
  font-size: 14px;
  cursor: pointer;
  color: #2e59ec;
  margin-left: 5px;
}
.jingwai .bottom-operation img {
  width: 16px;
  height: 16px;
}
.jingwai .weidu {
  margin-left: 5px;
  color: #ea3342;
}
.jingwai .yidu {
  color: #02bc7c;
}
.jingwai .yishoucang {
  color: #ff9000;
}
.jingwai .yiyujing {
  color: #ea3342;
}
.jingwai .bottom-comment-box {
  padding-right: 17px;
  justify-content: space-between;
  display: flex;
}
.jingwai .bottom-text-box {
  padding-right: 17px;
  display: flex;
  justify-content: space-between;
}
.jingwai .bottom-text-caozuo {
  display: flex;
}
.jingwai .bottom-text-caozuo > span:not(:first-child) {
  margin-left: 60px;
}
.jingwai .operation-box {
  margin-right: 16px;
  justify-content: flex-end;
  width: 100%;
  display: flex;
  font-size: 14px;
  color: #333333;
  line-height: 20px;
}
.jingwai .operation-box > div span:nth-child(1):nth-child(1) {
  position: relative;
  width: 16px;
  height: 16px;
  margin-right: 8px;
}
.jingwai .operation-box > div span:nth-child(1) span {
  position: absolute;
  top: 3px;
  left: 0;
}
.jingwai .comment-box-yujing {
  width: 60px;
  margin-left: 0;
  margin-right: 16px;
  padding: 2px 8px;
  font-size: 14px;
  font-weight: bold;
  background: #ffedef;
  color: #ea3342;
}
.original {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 14px;
  color: #2e59ec;
  margin-left: 16px;
}
.original img {
  position: relative;
  top: 4px;
}
</style>

<template>
  <!-- eslint-disable -->
  <div class="organizationManagement center-inner setting-biaoqian">
    <!-- 头部 -->
    <div class="organizationManagement_top">
      <div class="organizationManagement_top_content">
        <el-input clearable style="width:240px" v-model="labelName" placeholder="标签名称"></el-input>
        <el-input
          clearable
          style="width:800px;margin-left:32px"
          v-model="instructions"
          placeholder="标签描述"
        ></el-input>
        <el-button type="primary" size="small" class="xinzeng_z" @click="addreport">
          <img src="../../../assets/img/views/addb.png" alt />
          <span style="margin-left:5px">新增标签</span>
        </el-button>
      </div>
    </div>
    <!-- 主体 -->
    <div class="organizationManagement_content">
      <div class="content_table">
        <div>
          <el-table
            v-loading="loading"
            :row-style="{cursor: 'pointer'}"
            :data="tableData"
            stripe
            :header-cell-style="headerStyle"
            :cell-style="cellStyle"
            style="width: 100%;border: 1px solid  #E4E6EC;"
          >
            <el-table-column label="序号" :index="indexMethod" type="index" width="160"></el-table-column>
            <el-table-column label="标签名称" width="300">
              <template slot-scope="scope">
                <el-input
                  clearable
                  v-if="scope.row.editShow == true"
                  style="width:200px"
                  v-model="editLabelName"
                  placeholder="请输入标签名称"
                ></el-input>
                <span v-else>{{scope.row.labelName}}</span>
              </template>
            </el-table-column>
            <el-table-column label="说明" width="350" align="center">
              <template slot-scope="scope">
                <el-input
                  clearable
                  v-if="scope.row.editShow == true"
                  style="width:200px"
                  v-model="editInstructions"
                  placeholder="请输入标签 "
                ></el-input>
                <span v-else>{{scope.row.instructions}}</span>
              </template>
            </el-table-column>
            <el-table-column width="300" label="标记数据量">
              <template slot-scope="scope">
                <div @click="handel(scope.row)" class="name-wrapper">{{ scope.row.markNum }}</div>
              </template>
            </el-table-column>
            <el-table-column prop="lastUpdateTime" width="200" label="最后修改时间"></el-table-column>
            <el-table-column label="操作">
              <!-- <template slot-scope="scope"> -->
              <div class="table_handle" slot-scope="scope">
                <el-button
                  v-if="scope.row.editShow"
                  class="bianji"
                  type="text"
                  @click="add(scope.row)"
                >保存</el-button>
                <el-button v-else class="bianji" type="text" @click="edit(scope.row)">编辑</el-button>

                <div class="table_line"></div>
                <el-button
                  v-if="scope.row.editShow"
                  class="shanchu"
                  type="text"
                  @click="cancel(scope.row)"
                >取消</el-button>
                <el-button v-else class="shanchu" type="text" @click="deletes(scope.row)">删除</el-button>
              </div>
              <!-- </template> -->
            </el-table-column>
          </el-table>
          <div class="block">
            <span class="total">
              共{{ tablePage.total }}条, 当前显示第{{
              tablePage.page * tablePage.pageSize - tablePage.pageSize + 1
              }}-{{
              tablePage.page * tablePage.pageSize > tablePage.total
              ? tablePage.total
              : tablePage.page * tablePage.pageSize
              }}条
            </span>
            <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="tablePage.page"
              :page-sizes="tablePage.pageSizes"
              :page-size="tablePage.pageSize"
              layout="sizes, prev, pager, next,jumper"
              :total="tablePage.total"
            ></el-pagination>
          </div>
        </div>
      </div>
    </div>
    <deleteDialog ref="openDialog" @deleteFn="deleteFn" />
  </div>
</template>

<script>
import deleteDialog from "../../../components/deleteDialog";
import {
  labelList,
  addLabel,
  editLabel,
  deleteLabel
} from "../../../api/setting/list";
export default {
  data() {
    return {
      loading: false,
      headerStyle: {
        background: "#F6F7FA",
        textAlign: "center",
        fontSize: "16px",
        color: "#333"
      },
      cellStyle: {
        textAlign: "center"
      },
      // input 是否显示
      editShow: false,
      // 标签说明
      instructions: "",
      // 标签名称
      labelName: "",
      // 标签名称编辑值
      editLabelName: "",
      // 标签说明编辑值
      editInstructions: "",
      tablePage: {
        pageSizes: [10, 20, 30, 40, 50],
        page: 1,
        pageSize: 10,
        total: 0
      },
      tableData: []
    };
  },
  components: {
    deleteDialog
  },
  created() {
    this.getListInof();
  },
  methods: {
    // 分页
    // 分页
    handleSizeChange(val) {
      this.tablePage.pageSize = val;
      this.tablePage.page = 1;
      this.getListInof();
      window.scrollTo(0, 0);
    },
    handleCurrentChange(val) {
      this.tablePage.page = val;
      this.getListInof();
      window.scrollTo(0, 0);
    },
    // 序号
    indexMethod(index) {
      return (
        index +
        this.tablePage.page * this.tablePage.pageSize -
        this.tablePage.pageSize +
        1
      );
    },
    handel(row) {
      if (this.editShow == false) {
        this.$emit("changeHandle", row, 1);
      }
    },
    deletes(item) {
      item.message = "该标签";
      this.$refs.openDialog.openDialog(item);
    },
    // 删除 deleteLabel
    async deleteFn(item) {
      const res = await deleteLabel({
        labelId: item.labelId,
        organizationId: this.$store.state.user.organizationId
      });
      if (res.data.code == 200) {
        this.$message({
          message: "删除成功！",
          type: "success"
        });
        if (this.tableData.length == 1) {
          this.tablePage.page = this.tablePage.page - 1;
        }
      } else {
        this.$message.error("删除失败！");
      }

      this.getListInof();
    },
    edit(row) {
      this.editInstructions = row.instructions;
      this.editLabelName = row.labelName;
      this.tableData.forEach(item => {
        item.editShow = false;
      });
      row.editShow = true;
    },
    async add(row) {
      row.editShow = false;
      await editLabel({
        instructions: this.editInstructions,
        labelId: row.labelId,
        labelName: this.editLabelName
      });
      this.getListInof();
    },
    cancel(row) {
      row.editShow = false;
    },
    async addreport() {
      this.labelName = this.labelName.trim();
      this.instructions = this.instructions.trim();
      if (this.labelName == "" || this.instructions == "") {
        this.$message({
          message: "未输入完整!",
          type: "warning"
        });
      } else {
        let res = await addLabel({
          organizationId: this.$store.state.user.organizationId,
          instructions: this.instructions,
          labelName: this.labelName
        });
        if (res.data.code == 200) {
          this.$message({
            message: "添加标签成功！",
            type: "success"
          });
        } else {
          this.$message.error("添加失败，请稍后重试！");
        }
        this.instructions = "";
        this.labelName = "";
        this.getListInof();
      }
    },
    // 刷新页面
    async getListInof() {
      this.loading = true;
      const res = await labelList({
        pageSize: this.tablePage.pageSize,
        pageNum: this.tablePage.page,
        organizationId: this.$store.state.user.organizationId,
        isDel: 0
      });
      if (res != undefined) {
        this.tableData = res.data.rows;
        this.tablePage.total = res.data.total;
      }
      this.loading = false;
    }
  }
};
</script>

<style scoped>
.setting-biaoqian .organizationManagement {
  margin-top: 16px;
}

.setting-biaoqian .organizationManagement_title {
  color: #1a1a1a;
  font-size: 26px;
  font-weight: bold;
  margin-bottom: 24px;
}

.setting-biaoqian .organizationManagement_top {
  background: #ffffff;
  border-radius: 4px;
}

.setting-biaoqian .organizationManagement_top_content {
  display: flex;
  padding: 22px 24px;
  margin-top: 16px;
}
.setting-biaoqian .organizationManagement_content {
  background: #ffffff;
  border-radius: 4px;
  padding: 24px;
  margin-top: 16px;
}
.setting-biaoqian .allPeople {
  float: left;
  font-size: 16px;
  color: #333;
}

.setting-biaoqian .xinzeng_z {
  width: 116px;
  margin-left: 32px;
}

.setting-biaoqian .bianji,
.setting-biaoqian .table_line,
.setting-biaoqian .shanchu {
  display: inline-block;
  vertical-align: middle;
}

.setting-biaoqian .table_line {
  width: 1px;
  height: 14px;
  background: #e4e6ec;
  margin: 0 16px;
}

.setting-biaoqian .setting-biaoqian.bianji {
  color: #2e59ec;
  padding: 0;
}

.setting-biaoqian .shanchu {
  color: #ea3342;
  padding: 0;
}
.setting-biaoqian .name-wrapper {
  cursor: pointer;
  color: #2e59ec;
}
.setting-biaoqian .span-img {
  position: absolute;
  top: 0;
  left: 0;
}
.setting-biaoqian .span-img img {
  width: 18px;
  height: 18px;
}
</style>

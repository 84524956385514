<template>
  <div class="message-count setting-message count-box">
    <div class="message-bottom">
      <!-- 每一个模块 -->
      <div v-loading="loading">
        <div v-if="textData.length != 0">
          <div class="bottom-count" v-for="item in textData" :key="item.dataId">
            <div class="type-count">
              <div
                :class="[
                  'type',
                  item.tonalState == 2
                    ? 'red'
                    : item.tonalState == 1
                    ? 'neutral'
                    : 'obverse',
                ]"
              >
                {{
                  item.tonalState == 2
                    ? "负面"
                    : item.tonalState == 0
                    ? "正面"
                    : "中性"
                }}
              </div>
              <div v-if="item.mediaLink != null" class="type website">
                {{ item.mediaLink }}
              </div>
            </div>

            <div class="bottom-count-text">
              <div class="bottom-text-box">
                <p
                  class="bottom-title"
                  v-html="item.title ? item.title : ''"
                  @click="handelsee(item)"
                ></p>
                <span class="original" @click="lookOriginal(item.website)">
                  <img src="../../../assets/img/views/wenzhang.png" alt />
                  <span>查看原文</span>
                </span>
              </div>
              <p
                class="bottom-text"
                v-html="item.abstracts ? item.abstracts : ''"
                @click="handelsee(item)"
              ></p>
              <div class="caozuo-box">
                <div class="publics-caozuo">
                  <span class="publics source">
                    作者ID:{{ item.accurateId ? item.accurateId : "无" }}
                  </span>
                  <span class="publics source">
                    媒体性质:{{
                      item.mediaLink +
                      (item.mediaAttribute ? "/" + item.mediaAttribute : "") +
                      (item.mediaLevel ? "/" + item.mediaLevel : "")
                    }}
                  </span>
                  <span class="publics source">
                    来源/作者：
                    <span v-html="item.source ? item.source : '无'"></span>
                    <span>/</span>
                    <span v-html="item.author ? item.author : '无'"></span>
                  </span>
                  <!-- <span v-if="item.eventClass" class="publics bottom-gengduo">
                    事件分类：
                    <span>{{ item.eventList[0] }}</span>
                    <el-tooltip
                      v-if="item.eventClass && item.eventList.length >= 2"
                      class="item"
                      effect="dark"
                      placement="top"
                    >
                      <div slot="content" v-html="item.eventClass"></div>
                      <span class="gengduo-link">(更多)</span>
                    </el-tooltip>
                  </span>
                  <span class="publics bottom-gengduo" v-else>事件分类：无</span>-->
                  <span v-if="item.keyword" class="publics bottom-key">
                    关键字：
                    <span
                      v-for="(ite, indx) in item.keywordList"
                      :class="[
                        ite != item.keywordList[item.keywordList.length - 1]
                          ? 'styleMargin'
                          : '',
                      ]"
                      :key="indx"
                      >{{ ite }}</span
                    >
                    <el-tooltip
                      v-if="item.keyword && item.keywordArry.length > 3"
                      class="item"
                      effect="dark"
                      placement="top"
                    >
                      <div slot="content" v-html="item.keyword"></div>
                      <span class="gengduo-link">(更多)</span>
                    </el-tooltip>
                  </span>
                  <span v-else class="publics bottom-key">
                    关键字：
                    <span>无</span>
                  </span>
                </div>
                <div class="publics bottom-time">
                  {{ item.releaseTime ? item.releaseTime : "无" }}
                </div>
              </div>
            </div>
            <div class="caozuo-copy">
              <el-button type="primary" @click="copy(item)">一键复制</el-button>
            </div>
          </div>
        </div>
        <div class="analyse-container" v-else>
          <div class="analyse-container-img">
            <img src="../../../assets/img/views/analyse-img.png" />
            <p>暂无数据</p>
          </div>
        </div>
      </div>

      <div class="block">
        <span class="total" style="left: 7%">
          共{{ tablePage.total }}条, 当前显示第{{
            tablePage.page * tablePage.pageSize - tablePage.pageSize + 1
          }}-{{
            tablePage.page * tablePage.pageSize > tablePage.total
              ? tablePage.total
              : tablePage.page * tablePage.pageSize
          }}条
        </span>
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="tablePage.page"
          :page-sizes="tablePage.pageSizes"
          :page-size="tablePage.pageSize"
          layout="sizes, prev, pager, next,jumper"
          :total="tablePage.total"
        ></el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
import { reportList } from "../../../api/message/list";
export default {
  data() {
    return {
      // 列表 加载
      loading: false,
      // 内容
      textData: [],
      //页数
      tablePage: {
        pageSizes: [10, 20, 30, 40, 50],
        page: 1,
        pageSize: 10,
        total: 0,
      },
      serahList: {},
    };
  },
  methods: {
    //去详情
    handelsee(item) {
      let routeUrl = this.$router.resolve({
        path: "/systemSettings/index/original",
        query: {
          info: encodeURIComponent(
            JSON.stringify({
              id: item.dataId,
              organId: item.organId,
            })
          ),
        },
      });
      window.open(routeUrl.href, "_blank");
    },
    // 复制
    copy(item) {
      this.$publicFun.copyFun(item.copyContent);
    },
    resetNum() {
      this.serahList = {};
      this.tablePage.page = 1;
      this.getListInof();
    },
    // 获取列表
    async getListInof(obj, type) {
      obj ? (this.serahList = obj) : false;
      this.loading = true;
      let data = {
        organId: this.$store.state.user.organizationId,
        pageSize: this.tablePage.pageSize,
        pageNum: this.tablePage.page,
      };
      if (type == "search") {
        this.tablePage.page = 1;
      }
      if (Object.values(this.serahList).length > 0) {
        // 有值
        this.serahList.organId = this.$store.state.user.organizationId;
        this.serahList.pageSize = this.tablePage.pageSize;
        this.serahList.pageNum = 1;
        if (this.serahList.startTime) {
          this.serahList.startTimeStr =
            this.serahList.startTime.replace(" ", "T") + "+08:00";
          delete this.serahList.startTime;
        }
        if (this.serahList.abstracts) {
          this.serahList.content = this.serahList.abstracts;
          delete this.serahList.abstracts;
        }
        if (this.serahList.endTime) {
          this.serahList.endTimeStr =
            this.serahList.endTime.replace(" ", "T") + "+08:00";
          delete this.serahList.endTime;
        }
        data = this.serahList;
      }
      const res = await reportList(data);
      if (res.data.data.result) {
        this.textData = res.data.data.result;
        this.tablePage.total = res.data.data.total;
        this.textData.forEach((item) => {
          if (item.eventClass) {
            item.eventList = item.eventClass.split(" ");
            let reg = new RegExp(" ", "g"); //g代表全部
            item.eventClass = item.eventClass.replace(reg, "<br/>");
          } else {
            item.eventList = [];
          }
          if (item.keyword) {
            item.keywordList = item.keyword.split(" ");
            item.keywordArry = item.keyword.split(" ");
            if (item.keywordList.length > 3) {
              item.keywordList = item.keywordList.slice(0, 3);
            }
            let reg = new RegExp(" ", "g"); //g代表全部
            item.keyword = item.keyword.replace(reg, "<br/>");
          }
        });
        // this.heightText();
      }
      this.loading = false;
    },
    // 分页
    handleSizeChange(val) {
      this.tablePage.pageSize = val;
      this.tablePage.page = 1;
      this.getListInof();
      window.scrollTo(0, 0);
    },
    handleCurrentChange(val) {
      this.tablePage.page = val;
      this.getListInof();
      window.scrollTo(0, 0);
    },
    // 重置
    chongzhi() {
      this.formInline = {
        // 表单标题
        region: "title",
        // 作者模糊搜索
        input1: "",
        // 事件
        value: "",
        // 时间
        value1: [],
      };
      this.getListInof();
    },
    //查看原文
    lookOriginal(web) {
      window.open(web);
    },
    // 关键字高亮
    // heightText() {
    //   this.textData.forEach(item => {
    //     if (item.keyword != null) {
    //       let word = item.keyword.split("<br/>");
    //       if (word[word.length - 1] == "") {
    //         word.pop();
    //       }
    //       word.forEach(ite => {
    //         if (item.abstracts.includes(ite)) {
    //           let reg = new RegExp(ite, "g"); //g代表全部
    //           item.abstracts = item.abstracts.replace(
    //             reg,
    //             '<span style="color:red">' + ite + "</span>"
    //           );
    //         }
    //         if (item.title.includes(ite)) {
    //           let reg = new RegExp(ite, "g"); //g代表全部
    //           item.title = item.title.replace(
    //             reg,
    //             '<span style="color:red">' + ite + "</span>"
    //           );
    //         }
    //       });
    //     }
    //   });
    // }
  },
  created() {
    this.getListInof();
  },
};
</script>

<style scoped>
.message-count .select-item,
.el-select .select {
  margin: 0;
  width: 80px;
  height: 36px;
}
.message-count .input {
  width: 240px;
  margin-left: 32px;
}
.styleMargin {
  margin-right: 5px;
}
.message-count .el_font {
  margin-left: 32px;
  margin-right: 12px;
  font-size: 14px;
  font-weight: bold;
  color: #333333;
}
.message-count .defult1 {
  color: #ffffff;
  background: linear-gradient(313deg, #2e59ec 0%, #1a91ff 100%);
}
.message-count .defult2 {
  margin-left: 16px;
  background: #ffffff;
  border: 1px solid #e1e1e1;
  color: #333333;
}
.message-count .message-bottom {
  min-height: 200px;
  padding-bottom: 20px;
  margin-top: 16px;
  width: 100%;
  background: #ffffff;
  border-radius: 4px;
}
.message-bottom .noData {
  width: 100%;
  height: 100%;
  line-height: 200px;
  text-align: center;
}
.bottom-count {
  display: flex;
  padding: 24px 40px;
  border-bottom: 1px solid #e4e6ec;
}
.bottom-count:hover {
  box-shadow: 0px 2px 4px 4px rgb(0 0 0 / 6%);
}
.bottom-text-box {
  line-height: 25px;
  width: 1232px;
  display: flex;
}
.analyse-container {
  width: 100%;
  height: 300px;
  background: #ffffff;
  border-radius: 4px;
  margin-top: 16px;
  text-align: center;
}

.analyse-container-img {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.analyse-container-img p {
  font-size: 14px;
  color: #999999;
}
.bottom-title {
  cursor: pointer;
  max-width: 1008px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 18px;
  font-weight: bold;
  color: #333333;
}
.bottom-count-text span {
  display: inline-block;
}
.bottom-count-text {
  display: inline-block;
  margin-left: 40px;
  width: 1244px;
}
.type {
  padding: 0 5px;
  min-width: 76px;
  font-size: 14px;
  text-align: center;
  color: #ffffff;
  height: 28px;
  line-height: 28px;
  border-radius: 1px;
}
.website {
  height: 28px;
  background: #868bff;
  border-radius: 1px;
}
.publics {
  font-size: 14px;
  color: #b8bcc3;
  margin-top: 16px;
}
.bottom-text {
  cursor: pointer;
  overflow: hidden;
  height: 40px;
  line-height: 20px;
  font-size: 14px;
  color: #666666;
  margin-top: 16px;
}
.gengduo-link {
  cursor: pointer;
  color: #2e59ec;
  margin-left: 5px;
}
.original {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 14px;
  color: #2e59ec;
  margin-left: 16px;
}
.original img {
  position: relative;
  top: 4px;
}
.caozuo-copy .el-button {
  width: 100%;
  padding: 9px 24px;
  font-size: 14px;
  font-weight: bold;
}

.type-count {
  margin-top: 19px;
}
.website {
  margin-top: 24px;
}
.caozuo-box {
  width: 1232px;
  display: flex;
  justify-content: space-between;
}
.publics-caozuo {
  display: flex;
  align-items: center;
}
.publics-caozuo > span {
  margin-left: 45px;
}
.publics-caozuo > span:nth-child(1) {
  margin-left: 0;
}
.caozuo-copy {
  margin-left: 48px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.chongzhi {
  color: #333333;
  width: 60px;
  font-size: 14px;
  height: 32px;
  box-sizing: border-box;
}

.xia-span {
  font-size: 14px;
  color: #2e59ec;
  width: 41px;
  display: inline-block;
  vertical-align: middle;
}
.xia-img {
  display: inline-block;
  vertical-align: middle;
  line-height: 50px;
}

.bottom-key {
  margin-left: 0px;
}
.message-count .block .total {
  left: 12%;
}
</style>
